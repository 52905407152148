import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';

import CommonHeader from '@components/CommonHeader';
import { MODULES, ROUTES, DEFAULT_SORT_CONFIG } from '@common/constants';

import history from '../../../historyData';
import HeatingSystemsTable from '../components/HeatingSystemsTable';
import { LIST_HEATING_SYSTEMS } from '../graphql/Queries';
import { DELETE_HEATING_SYSTEM } from '../graphql/Mutations';

const HeatingSystems = () => {
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentHeatingSystem, setCurrentHeatingSystem] = useState(null);

  const [
    listHeatingSystems,
    { data: heatingSystemsData, loading: dataLoading },
  ] = useLazyQuery(LIST_HEATING_SYSTEMS);

  const [deleteHeatingSystem, { loading: deleteLoading }] = useMutation(
    DELETE_HEATING_SYSTEM,
  );

  const getHeatingSystems = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listHeatingSystems({
        variables: {
          filter: {
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive:
              isActiveFilter !== null ? JSON.parse(isActiveFilter) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const handleFilterApply = () => {
    getHeatingSystems();
    setFilterVisible(false);
  };

  useEffect(() => {
    if (isActiveFilter === null) {
      handleFilterApply();
    }
  }, [isActiveFilter]);

  useEffect(() => {
    getHeatingSystems();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value) => {
    setIsDeleteOpen(true);
    setCurrentHeatingSystem(value);
  };

  const handleDeleteHeatingSystem = async (id) => {
    try {
      const res = await deleteHeatingSystem({ variables: { where: { id } } });
      if (res?.data) {
        setCurrentHeatingSystem(null);
        setIsDeleteOpen(false);
        getHeatingSystems();
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <CommonHeader
        heading={MODULES?.HEATING_SYSTEMS}
        customLink={ROUTES?.MASTER}
      >
        <Button
          className="common-button"
          size="middle"
          icon={<PlusOutlined />}
          onClick={() =>
            history?.push(`${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}/add`)
          }
        >
          Add Heating System
        </Button>
      </CommonHeader>
      <Card className="full-height-without-nav">
        <HeatingSystemsTable
          handleTableChange={handleTableChange}
          paginationConfig={paginationConfig}
          sortConfig={sortConfig}
          heatingSystemsData={heatingSystemsData?.adminHeatingSystems}
          loading={dataLoading}
          onSearchChange={onSearchChange}
          handleDeleteModalOpen={handleDeleteModalOpen}
          isActiveFilter={isActiveFilter}
          setIsActiveFilter={setIsActiveFilter}
          handleFilterApply={handleFilterApply}
          filterVisible={filterVisible}
          setFilterVisible={setFilterVisible}
        />
      </Card>
      <Modal
        title="Delete the Heating System"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteHeatingSystem(currentHeatingSystem?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentHeatingSystem(null);
        }}
      >
        Are you sure you want to delete this heating system?
      </Modal>
    </>
  );
};

export default HeatingSystems;
