import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useState } from 'react';

import { EXPORTS } from '../../../common/constants';
import { CREATE_EXPORT_JOB } from '../graphql/Mutations';
import ExportDateRangeModal from './ExportDateRangeModal';

const Exports = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportType, setIsExportType] = useState('');

  const [createExportJob] = useMutation(CREATE_EXPORT_JOB, {
    onError: () => {},
  });

  const handleExport = async (values) => {
    setIsLoading(true);
    const response = await createExportJob({
      variables: {
        type: exportType,
        filters: {
          dateRange:
            values?.date?.[0] && values?.date?.[1]
              ? {
                  from: values?.date?.[0]?.startOf('day'),
                  to: values?.date?.[1]?.endOf('day'),
                }
              : undefined,
          organizationId: values?.organisation,
        },
      },
    });
    if (response?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsLoading(false);
  };

  return (
    <>
      <ExportDateRangeModal
        form={form}
        title={
          exportType === EXPORTS?.DIY_MEASURES?.value
            ? 'DIY Measures Export'
            : 'Other Measures Export'
        }
        onFinish={handleExport}
        loadings={isLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <div className="d-flex justify-end">
        <Button
          type="primary"
          size="large"
          className="mr-16"
          onClick={() => {
            setIsExportType(EXPORTS?.DIY_MEASURES?.value);
            setIsModalOpen(true);
          }}
          icon={<ExportOutlined />}
        >
          Export DIY Measures
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setIsExportType(EXPORTS?.OTHER_MEASURES?.value);
            setIsModalOpen(true);
          }}
          icon={<ExportOutlined />}
        >
          Export Other Measures
        </Button>
      </div>
    </>
  );
};

export default Exports;
