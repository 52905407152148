import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ROUTES, TABS } from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { getSignedUrl, fileUpload } from '@common/utils';
import { AppContext } from '@context';

import OrganizationForm from '../components/OrganizationForm';
import history from '../../../historyData';

import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from '../graphql/Mutations';
import { GET_ORGANIZATION_DETAIL, GET_SIGNED_URL } from '../graphql/Queries';
import OrganizationTab from '../components/OrganizationTab';

const OrganizationDetails = () => {
  const { id: organizationId, mode } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [organizationLogo, setOrganizationLogo] = useState([]);
  const [isView, setIsView] = useState(false);

  const [createOrganization, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onError: () => {},
    },
  );

  const [
    updateOrganization,
    { loading: updateLoading },
  ] = useMutation(UPDATE_ORGANIZATION, { onError: () => {} });

  const [getOrganizationData, { data: organizationData }] = useLazyQuery(
    GET_ORGANIZATION_DETAIL,
  );

  const onEdit = () => {
    setIsView(false);
    history?.push(
      `${ROUTES?.ORGANIZATIONS}/edit/${organizationId}/${TABS?.ORGANIZATION?.key}`,
    );
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(`${ROUTES?.ORGANIZATIONS}`);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const dataToSend = {
      ...values,
    };
    try {
      let key;
      if (values?.logoUrl) {
        if (values?.logoUrl?.fileList?.length > 0) {
          await getSignedUrl(values?.logoUrl?.file, GET_SIGNED_URL)?.then(
            async (res) => {
              const signedImageUrl = res?.getCompanyUploadSignedUrl?.signedUrl;
              key = res?.getCompanyUploadSignedUrl?.key;
              await fileUpload(
                signedImageUrl,
                values?.logoUrl?.fileList?.[0]?.originFileObj,
              );
              dataToSend.logo = key;
            },
          );
        } else if (values?.logoUrl?.file?.status === 'removed') {
          dataToSend.logo = null;
        } else {
          delete dataToSend.logo;
        }
      }
      const { logoUrl, email, slug, ...restValues } = dataToSend;
      if (organizationId) {
        try {
          await updateOrganization({
            variables: {
              where: { id: organizationId },
              data: { ...restValues },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createOrganization({
            variables: {
              data: { ...restValues, email, slug },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const getOrganizationDetail = async () => {
    try {
      await getOrganizationData({
        variables: { where: { id: organizationId } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationDetail();
    }
    if (mode === 'view') {
      setIsView(true);
    }
  }, []);

  useEffect(() => {
    if (organizationData) {
      const organizationValue = {
        name: organizationData?.getCompany?.name,
        email: organizationData?.getCompany?.email,
        logoUrl: organizationData?.getCompany?.logo,
        url: organizationData?.getCompany?.url,
        slug: organizationData?.getCompany?.slug,
      };
      form?.setFieldsValue(organizationValue);
      setOrganizationLogo(
        organizationData?.getCompany?.logo
          ? [{ url: organizationData?.getCompany?.logo }]
          : [],
      );
    }
  }, [organizationData]);

  return (
    <>
      <CommonHeader
        heading={
          organizationId
            ? `${mode} Organisation Detail`
            : 'Add New Organisation Detail'
        }
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <div className="header-btn-wrapper">
          {isView ? (
            <Button className="common-button" size="middle" onClick={onEdit}>
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                onClick={onCancelEdit}
              >
                Cancel
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form?.submit}
                loading={createLoading || updateLoading}
                disabled={createLoading || updateLoading || isDisabled}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </CommonHeader>
      <OrganizationTab>
        <OrganizationForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          organizationId={organizationId}
          organizationLogo={organizationLogo}
          setOrganizationLogo={setOrganizationLogo}
          isView={isView}
        />
      </OrganizationTab>
    </>
  );
};

export default OrganizationDetails;
