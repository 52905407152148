import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ROUTES } from '@common/constants';
import { formValidatorRules } from '@common/utils';
import LogoTextComponent from '@components/LogoTextComponent';
import { AppContext } from '@context';

import './auth.less';
import { LOGIN_USER } from './graphql/Mutations';
import { GET_USER } from './graphql/Queries';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);

  const [getUser, { loading: currentUserLoading }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [loginUser, { loading: loginLoading }] = useMutation(LOGIN_USER, {
    onError() {},
  });

  function successCallback(accessToken, userData, permissions, refreshToken) {
    initializeAuth(accessToken, userData, permissions, refreshToken);
    history?.replace('/');
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim(),
        password: values?.password?.trim(),
      };
      const response = await loginUser({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.loginUser?.accessToken;
        const refreshToken = response?.data?.loginUser?.refreshToken;
        const res = await getUser({
          context: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        });
        if (res?.data && successCallback) {
          const userData = res?.data?.getUser;
          successCallback(
            accessToken,
            userData,
            userData?.permissions,
            refreshToken,
          );
        } else {
          form?.setFieldsValue(values);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="login-wrap d-flex align-center justify-start">
      <Card className="full-width">
        <Spin
          spinning={loginLoading || currentUserLoading}
          wrapperClassName="full-width"
        >
          <div className="text-center mb-32 d-flex justify-center align-center">
            <LogoTextComponent height="80px" />
          </div>
          <Form
            name="Login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
            form={form}
          >
            <Form.Item
              name="email"
              rules={[{ ...required, message: 'Please Enter Email!' }, email]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Enter email"
                maxLength={255}
              />
            </Form.Item>

            <Form.Item
              name="password"
              className="mb-16"
              rules={[{ ...required, message: 'Please Enter Password!' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter password"
              />
            </Form.Item>
            <Form.Item className=" full-width mb-8">
              <Button type="primary" className="full-width" htmlType="submit">
                Login
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-8">
              <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default Login;
