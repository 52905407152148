import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import HeatingSystems from './pages/HeatingSystems';
import CreateHeatingSystems from './pages/CreateHeatingSystems';

const HeatingSystemsWrapper = () => (
  <>
    <>
      <Switch>
        <Route
          exact
          path={`${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}`}
          render={(props) => <HeatingSystems {...props} />}
        />
        <Route
          exact
          path={`${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}/add`}
          render={(props) => <CreateHeatingSystems {...props} />}
        />
        <Route
          exact
          path={`${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}/:mode/:id`}
          render={(props) => <CreateHeatingSystems {...props} />}
        />
      </Switch>
    </>
  </>
);

export default HeatingSystemsWrapper;
