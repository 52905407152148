import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';

import { MODULES, ROUTES, DEFAULT_SORT_CONFIG } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import history from '../../../historyData';
import MaterialsTable from '../components/MaterialsTable';
import { LIST_MATERIAL_QUESTION, LIST_MATERIALS } from '../graphql/Queries';
import {
  DELETE_MATERIAL,
  DELETE_MATERIAL_QUESTION,
} from '../graphql/Mutations';
import MaterialQuestionsModal from '../components/MaterialQuestionsModal';
import MaterialTab from '../components/MaterialTabs';

const Materials = () => {
  const { id: materialCategoryId } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [questionEdit, setExtensionEdit] = useState(null);
  const [materialId, setMaterialId] = useState(null);

  const [
    listMaterials,
    { data: materialsData, loading: dataLoading },
  ] = useLazyQuery(LIST_MATERIALS);

  const [deleteMaterial, { loading: deleteLoading }] = useMutation(
    DELETE_MATERIAL,
  );

  const [
    deleteMaterialQuestion,
    { loading: deleteQuestionLoading },
  ] = useMutation(DELETE_MATERIAL_QUESTION);

  const [
    listMaterialQuestions,
    { data: materialQuestionsData, loading: questionDataLoading },
  ] = useLazyQuery(LIST_MATERIAL_QUESTION);

  const getMaterials = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listMaterials({
        variables: {
          where: {
            materialCategoryId,
          },
          filter: {
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive:
              isActiveFilter !== null ? JSON.parse(isActiveFilter) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const getMaterialQuestions = async (expand, id) => {
    if (expand) {
      try {
        await listMaterialQuestions({
          variables: {
            where: { materialId: id },
          },
          fetchPolicy: 'network-only',
        });
      } catch (error) {
        return error;
      }
    }
  };

  const handleFilterApply = () => {
    getMaterials();
    setFilterVisible(false);
  };

  useEffect(() => {
    if (isActiveFilter === null) {
      handleFilterApply();
    }
  }, [isActiveFilter]);

  useEffect(() => {
    getMaterials();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value, type) => {
    setIsDeleteOpen(true);
    setCurrentMaterial({ ...value, type });
  };

  const handleDeleteMaterial = async (id) => {
    try {
      if (currentMaterial?.type === 'questions') {
        await deleteMaterialQuestion({
          variables: { where: { id } },
          onCompleted: () => {
            setCurrentMaterial(null);
            setIsDeleteOpen(false);
            if (expandedRow) {
              getMaterialQuestions(true, expandedRow);
            } else {
              getMaterials();
            }
          },
        });
      } else {
        await deleteMaterial({
          variables: { where: { id } },
          onCompleted: () => {
            setCurrentMaterial(null);
            setIsDeleteOpen(false);
            if (expandedRow) {
              getMaterialQuestions(true, expandedRow);
            } else {
              getMaterials();
            }
          },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleExtensionModalClose = () => {
    setExtensionEdit(null);
    setMaterialId(null);
    setQuestionModalOpen(false);
  };

  return (
    <>
      <CommonHeader heading={MODULES?.MATERIALS} customLink={ROUTES?.MASTER}>
        <Button
          className="common-button"
          size="middle"
          icon={<PlusOutlined />}
          onClick={() =>
            history?.push(
              `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}${ROUTES?.MATERIALS}/${materialCategoryId}/add`,
            )
          }
        >
          Add Material
        </Button>
      </CommonHeader>
      <MaterialTab>
        <div className="tab-table">
          <MaterialsTable
            handleTableChange={handleTableChange}
            paginationConfig={paginationConfig}
            sortConfig={sortConfig}
            materialsData={materialsData?.adminMaterials}
            loading={dataLoading}
            onSearchChange={onSearchChange}
            handleDeleteModalOpen={handleDeleteModalOpen}
            isActiveFilter={isActiveFilter}
            setIsActiveFilter={setIsActiveFilter}
            handleFilterApply={handleFilterApply}
            filterVisible={filterVisible}
            setFilterVisible={setFilterVisible}
            getMaterialQuestions={getMaterialQuestions}
            expandedRow={expandedRow}
            setExpandedRow={setExpandedRow}
            materialQuestionsData={
              materialQuestionsData?.adminMaterialQuestions
            }
            questionDataLoading={questionDataLoading}
            setQuestionModalOpen={setQuestionModalOpen}
            setExtensionEdit={setExtensionEdit}
            setMaterialId={setMaterialId}
            materialCategoryId={materialCategoryId}
          />
        </div>
      </MaterialTab>
      <Modal
        title={`Delete the Material ${currentMaterial?.type}`}
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteMaterial(currentMaterial?.id)}
        okButtonProps={{ loading: deleteLoading || deleteQuestionLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentMaterial(null);
        }}
      >
        Are you sure you want to delete this Material {currentMaterial?.type}?
      </Modal>
      <MaterialQuestionsModal
        open={questionModalOpen}
        onCancel={handleExtensionModalClose}
        questionEdit={questionEdit}
        expandedRow={expandedRow}
        materialId={materialId}
        getMaterialQuestions={getMaterialQuestions}
      />
    </>
  );
};

export default Materials;
