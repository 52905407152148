import {
  AppstoreAddOutlined,
  DashboardOutlined,
  ExportOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';

import {
  MODULES,
  PERMISSIONS_KEY,
  ROUTES,
  SIDEBAR_ADMIN_DROPDOWNS,
} from '@common/constants';
import LogoTextComponent from '@components/LogoTextComponent';
import MobileLogoComponent from '@components/MobileLogoComponent';
import { AppContext } from '@context';

function getItem(label, key, icon, children, onTitleClick, type) {
  const item = {
    key,
    icon,
    children,
    label,
    title: label,
    type,
  };

  if (onTitleClick) {
    item.onTitleClick = (data) => onTitleClick(children, data);
  }

  return item;
}

const lineBarMenuItems = [
  getItem(MODULES?.PROFILE, ROUTES?.PROFILE, <UserOutlined />),
  getItem(MODULES?.LOGOUT, ROUTES?.LOGOUT, <PoweroffOutlined />),
];

export const MENU_ITEMS = [
  {
    key: MODULES?.DASHBOARD,
    route: ROUTES?.MAIN,
    icon: <DashboardOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.ORGANIZATIONS,
    route: ROUTES?.ORGANIZATIONS,
    icon: <TeamOutlined />,
    permissionKey: PERMISSIONS_KEY?.OVERVIEW,
    tabKeys: [],
    subMenu: [],
  },
  {
    key: MODULES?.MASTER,
    route: ROUTES?.MASTER,
    icon: <AppstoreAddOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [
      {
        key: MODULES?.HOUSE_TYPES,
        route: `${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.HOUSE_SHAPES,
        route: `${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.ROOF_TYPES,
        route: `${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.ROOF_EXTENSIONS,
        route: `${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.MATERIAL_CATEGORIES,
        route: `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`,
        permissionKey: '',
        tabKeys: [],
      },
      {
        key: MODULES?.HEATING_SYSTEMS,
        route: `${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}`,
        permissionKey: '',
        tabKeys: [],
      },
    ],
  },
  {
    key: MODULES?.EXPORTS,
    route: ROUTES?.EXPORTS,
    icon: <ExportOutlined />,
    permissionKey: '',
    tabKeys: [],
    subMenu: [],
  },
];

const rootSubmenuKeys = [];

function Sidebar({ collapsed, setCollapsed, isDesktop, isActive, setActive }) {
  const history = useHistory();
  const location = useLocation();
  const {
    getCurrentUser,
    // state: { permissions },
  } = useContext(AppContext);
  const [openKeys, setOpenKeys] = useState([]);
  const { firstName = '', lastName = '' } = getCurrentUser() || {};
  const [isLogoutPrompts, setIsLogoutPrompts] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const fullName =
    firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '-';

  const handleSubMenuTitleClick = (children, data) => {
    const isInclude = SIDEBAR_ADMIN_DROPDOWNS?.includes(
      location?.pathname?.split('/')?.[1],
    );
    if (isInclude && !location?.pathname?.includes(data?.key)) {
      setSelectedKeys(children?.[0]?.key);
      history?.push(children?.[0]?.key);
    }
  };

  useEffect(() => {
    const isInclude = SIDEBAR_ADMIN_DROPDOWNS?.includes(
      location?.pathname?.split('/')?.[1],
    );
    setOpenKeys([`/${location?.pathname?.split('/')?.[1]}`]);
    if (
      location?.pathname === '/' ||
      location?.pathname === ROUTES?.VISUALISATION
    ) {
      setSelectedKeys([ROUTES?.MAIN]);
    } else if (isInclude) {
      setSelectedKeys([
        `/${location?.pathname?.split('/')?.[1]}/${
          location?.pathname?.split('/')?.[2]
        }`,
      ]);
    } else {
      setSelectedKeys([`/${location?.pathname?.split('/')?.[1]}`]);
    }
  }, [location?.pathname]);

  const filteredMenuItems = MENU_ITEMS?.map(
    (menuItem) => {
      const { subMenu } = menuItem;
      // const hasPermission = hasNonEmptyPermission(permissionKey, permissions);
      // const hasMatchingSubmenu = subMenu?.some((subMenuItem) => {
      //   const { permissionKey: subPermissionKey } = subMenuItem;
      //   return hasNonEmptyPermission(subPermissionKey, permissions);
      // });
      // const hasMatchingTab = subMenu?.some((subMenuItem) => {
      //   const { tabKeys } = subMenuItem;
      //   return hasMatchingTabKey(tabKeys, permissions);
      // });
      const filteredSubMenu = subMenu?.map((subMenuItem) =>
        // const {
        //   permissionKey: subPermissionKey,
        //   tabKeys: subTabKeys,
        // } = subMenuItem;
        // const hasPermissionSubmenu = hasNonEmptyPermission(
        //   subPermissionKey,
        //   permissions,
        // );
        // const hasMatchingTabSubmenu = hasMatchingTabKey(subTabKeys, permissions);
        getItem(subMenuItem?.key, subMenuItem?.route),
      );
      // if (hasPermission || hasMatchingTab || hasMatchingSubmenu) {
      return getItem(
        menuItem?.key,
        menuItem?.route,
        menuItem?.icon,
        filteredSubMenu?.length > 0 ? filteredSubMenu : undefined,
        filteredSubMenu?.length && handleSubMenuTitleClick,
      );
    },
    // return undefined;
  )?.filter((item) => item);

  const onMenuSelect = (e) => {
    if (e?.key === ROUTES?.LOGOUT) {
      setIsLogoutPrompts(true);
    } else {
      history?.push(e?.key);
      setActive(!isActive);
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys?.find((key) => openKeys?.indexOf(key) === -1);
    if (rootSubmenuKeys?.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    history?.push(keys?.[1]);
  };

  return (
    <div className="side-bar">
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isLogoutPrompts}
        onOk={() => history?.replace(ROUTES?.LOGOUT)}
        onCancel={() => setIsLogoutPrompts(false)}
      >
        Are you sure you want to
        <strong> logout </strong>?
      </Modal>
      <div>
        {collapsed ? (
          <div className="d-flex align-center flex-vertical">
            <MenuUnfoldOutlined
              className="trigger menu-collapsed-unfold"
              onClick={() => setCollapsed(!collapsed)}
            />
            <Link id="logo" to={ROUTES?.MAIN}>
              <MobileLogoComponent className="mr-0" height="20px" />
            </Link>
          </div>
        ) : (
          <div className="d-flex justify-between ml-24 mr-24">
            <Link id="logo" to={ROUTES?.MAIN}>
              <LogoTextComponent height="40px" />
            </Link>
            {collapsed && isDesktop ? (
              <MenuUnfoldOutlined
                className="trigger mr-32"
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : (
              <>
                {isDesktop && (
                  <MenuFoldOutlined
                    className="trigger ml-32"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="sidebar-content">
        <Menu
          theme="light"
          mode="inline"
          openKeys={collapsed ? undefined : openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
          defaultOpenKeys={[`/${location?.pathname?.split('/')?.[1]}`]}
          onClick={onMenuSelect}
          items={filteredMenuItems}
        />
      </div>
      <div className="sidebar-bottom-content">
        {!collapsed ? (
          <div className=" d-flex flex-vertical align-center">
            <div
              className={`d-flex ${
                location?.pathname?.includes(ROUTES?.PROFILE) &&
                'sidebar-profile'
              }`}
            >
              <SmileOutlined />
              <div
                className="pointer ml-8 user-name"
                title={fullName}
                onClick={() => history?.replace(ROUTES?.PROFILE)}
              >
                {fullName}
              </div>
            </div>
            <div
              className="mt-16 logout-btn pointer"
              onClick={() => setIsLogoutPrompts(true)}
            >
              LOGOUT
            </div>
          </div>
        ) : (
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            defaultOpenKeys={[`/${location?.pathname?.split('/')?.[1]}`]}
            onClick={onMenuSelect}
            items={lineBarMenuItems}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
