import { useLazyQuery } from '@apollo/client';
import { DatePicker, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { DATE_FORMATS, LIMIT, REGEX } from '../../../common/constants';

import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { DASHBOARD_MOST_LIKED_RECOMMENDATIONS } from '../graphql/Queries';
import CompanySelect from './CompanySelect';

const { RangePicker } = DatePicker;

const ViewLikedRecommendations = ({ isModalOpen, setIsModalOpen }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [isRecommendationsLoading, setIsRecommendationsLoading] = useState(
    false,
  );
  const [isEmptyRecommendationList, setIsEmptyRecommendationList] = useState(
    false,
  );
  const [
    isFetchMoreRecommendationsLoading,
    setIsFetchMoreRecommendationsLoading,
  ] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [emails, setEmails] = useState([]);

  const [dashboardMostLikedRecommendations] = useLazyQuery(
    DASHBOARD_MOST_LIKED_RECOMMENDATIONS,
    {
      onCompleted: (response) => {
        if (response?.dashboardMostLikedRecommendations?.data?.length < LIMIT) {
          setIsActive(false);
        }
        if (response?.dashboardMostLikedRecommendations?.count === 0) {
          setIsEmptyRecommendationList(true);
        }
        setRecommendations([
          ...recommendations,
          ...response?.dashboardMostLikedRecommendations?.data,
        ]);
        setIsRecommendationsLoading(false);
        setIsFetchMoreRecommendationsLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (isModalOpen) {
      setIsRecommendationsLoading(true);
      setIsActive(true);
      setRecommendations([]);
      dashboardMostLikedRecommendations({
        variables: {
          filters: {
            skip: 0,
            limit: LIMIT,
            dateRange:
              selectedDateRange?.from && selectedDateRange?.to
                ? {
                    from: selectedDateRange?.from,
                    to: selectedDateRange?.to,
                  }
                : undefined,
            organizationId: selectedCompany || undefined,
            excludeEmails: emails,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, selectedCompany, selectedDateRange, emails]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedCompany();
      setSelectedDateRange({});
      setEmails([]);
      setInvalidEmails([]);
    }
  }, [isModalOpen]);

  const emailValidator = (values) => {
    const invalidInputs = values?.filter(
      (value) => !value?.match(REGEX?.EMAIL),
    );
    setInvalidEmails(invalidInputs);
    setEmails(values);
  };

  const onPreventMouseDown = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleMembersScroll = () => {
    if (!isFetchMoreRecommendationsLoading && !isRecommendationsLoading) {
      const currentLength = recommendations?.length;
      setIsFetchMoreRecommendationsLoading(true);
      dashboardMostLikedRecommendations({
        variables: {
          filters: {
            skip: currentLength,
            limit: LIMIT,
            dateRange:
              selectedDateRange?.from && selectedDateRange?.to
                ? {
                    from: selectedDateRange?.from,
                    to: selectedDateRange?.to,
                  }
                : undefined,
            organizationId: selectedCompany || undefined,
            excludeEmails: emails,
          },
        },
      });
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.recommendation?.Name || '-',
    },
    {
      title: 'LIKES',
      dataIndex: 'likes',
      key: 'likes',
      ellipsis: true,
      width: 50,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.likes || '-',
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      key: 'cost',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        `${
          record?.recommendation?.cost_l >= 0
            ? `£${Math.round(record?.recommendation?.cost_l)}`
            : 'N/A'
        } -  ${
          record?.recommendation?.cost_h >= 0
            ? `£${Math.round(record?.recommendation?.cost_h)}`
            : 'N/A'
        }`,
    },
  ];

  const locale = {
    emptyText: isEmptyRecommendationList ? '' : <span />,
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleMembersScroll, isActive);

  const onRangePickerChange = (values) => {
    const rangeObj = {
      from: values?.[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };
    setSelectedDateRange(values ? rangeObj : {});
  };

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={1000}
      title="Most Liked Recommendations"
    >
      <div>
        <div className="d-flex justify-end mb-32">
          <CompanySelect
            className="filter-select mr-16"
            value={selectedCompany}
            onChange={(value) => setSelectedCompany(value)}
          />
          <SelectComponent
            mode="tags"
            placeholder="Enter Exclude Emails"
            className="filter-select mr-16"
            tokenSeparators={[',', ' ']}
            popupClassName="select-email-dropdown"
            value={emails}
            onChange={(values) => emailValidator(values)}
            tagRender={({ label, value, closable, onClose }) => (
              <Tag
                closable={closable}
                onClose={(e) => {
                  e?.preventDefault();
                  onClose();
                }}
                onMouseDown={onPreventMouseDown}
                color={invalidEmails?.includes(value) ? 'red' : ''}
              >
                {label}
              </Tag>
            )}
          />
          <RangePicker
            value={[selectedDateRange?.from, selectedDateRange?.to]}
            popupClassName="picker-with-scroll"
            format={DATE_FORMATS?.REGULAR}
            onChange={onRangePickerChange}
            disabledDate={(current) =>
              current && current?.startOf('day') > moment()?.startOf('day')
            }
            placeholder={['From Date', 'To Date']}
          />
        </div>
        <div className="mt-30 flex-vertical">
          {isRecommendationsLoading ? (
            <div className="view-member-modal">
              <LoaderComponent
                size="large"
                setHeight={40}
                spinning={isRecommendationsLoading}
              />
            </div>
          ) : (
            <CommonTable
              className="members-table"
              locale={locale}
              columns={columns}
              data={recommendations}
              loading={
                isRecommendationsLoading || isFetchMoreRecommendationsLoading
              }
              scroll={{ y: 350 }}
              rowKey={() =>
                Math?.random()?.toString(36)?.substring(2) +
                new Date()?.getTime()?.toString(36)
              }
              onRow={() => ({
                ref: infiniteScrollRef,
              })}
            />
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

export default ViewLikedRecommendations;
