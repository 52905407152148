import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import RoofTypes from './pages/RoofTypes';
import CreateRoofTypes from './pages/CreateRoofTypes';

const RoofTypesWrapper = () => (
  <>
    <Switch>
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}`}
        render={(props) => <RoofTypes {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}/:type/add`}
        render={(props) => <CreateRoofTypes {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}/:type/:mode/:id`}
        render={(props) => <CreateRoofTypes {...props} />}
      />
    </Switch>
  </>
);

export default RoofTypesWrapper;
