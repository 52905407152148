import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { BREAKPOINTS } from '@common/constants';
import RouterPrompt from '@components/RouterPrompt';
import { AppContext } from '@context';

import './app.less';
import AppHeader from './components/header/AppHeader';
import Sidebar from './components/sidebar/Sidebar';
import ContentRoutes from './ContentRoutes';

const { Content, Sider } = Layout;

const App = () => {
  const { state } = useContext(AppContext);
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
      if (
        // eslint-disable-next-line no-undef
        window.innerWidth > BREAKPOINTS.desktop ||
        // eslint-disable-next-line no-undef
        window.innerWidth < BREAKPOINTS.tablet
      ) {
        setCollapsed(false);
        // eslint-disable-next-line no-undef
      } else {
        setCollapsed(true);
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (
      // eslint-disable-next-line no-undef
      window.innerWidth > BREAKPOINTS.desktop ||
      // eslint-disable-next-line no-undef
      window.innerWidth < BREAKPOINTS.tablet
    ) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
    // eslint-disable-next-line no-undef
  }, [window.innerWidth]);

  return (
    <Layout hasSider>
      <RouterPrompt openPrompt={state?.showPrompt} />
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        theme="light"
        collapsed={isDesktop ? collapsed : false}
        className={isActive ? 'close' : null}
        breakpoint="md"
      >
        <Sidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isDesktop={isDesktop}
          isActive={isActive}
          setActive={setActive}
        />
      </Sider>
      <Layout className="site-layout">
        <AppHeader isDesktop={isDesktop} handleOverlay={handleOverlay} />
        <Content className="wrapper">
          <div id="header-right-content-phones" />
          <ContentRoutes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
