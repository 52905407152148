import { Column } from '@ant-design/plots';
import React from 'react';

const CommonEpcChart = ({ data }) => {
  const config = {
    data,
    xField: 'type',
    yField: 'count',
    insetBottom: 10,
    style: {
      fill: '#407076',
      maxWidth: 70,
    },
    axis: {
      x: {
        tick: false,
        labelFontSize: 14,
      },
      y: {
        tick: false,
        labelFontSize: 14,
        grid: true,
        gridLineWidth: 2,
        gridLineDash: [1, 1],
      },
    },
    height: 500,
  };

  return <Column {...config} />;
};

export default CommonEpcChart;
