import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import Profile from './pages/Profile';
import './profile.less';

const ProfileWrapper = () => (
  <Switch>
    <Route path={ROUTES?.PROFILE} exact component={Profile} />
  </Switch>
);

export default ProfileWrapper;
