import { DatePicker, Divider, Form } from 'antd';
import React, { useState } from 'react';

import moment from 'moment';

import { DATE_FORMATS } from '../../../common/constants';
import ModalComponent from '../../../components/ModalComponent';
import CompanySelect from './CompanySelect';

const { Item } = Form;
const { RangePicker } = DatePicker;

const ExportDateRangeModal = ({
  title,
  isModalOpen,
  setIsModalOpen,
  loadings,
  onFinish,
  form,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);

  return (
    <ModalComponent
      title={title}
      okText="Download"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={loadings}
    >
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => setValidationTriggered(true)}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Item label="Date" name="date">
          <RangePicker
            format={DATE_FORMATS?.REGULAR}
            className="full-width"
            disabledDate={(current) =>
              current && current?.startOf('day') > moment()?.startOf('day')
            }
            placeholder={['From Date', 'To Date']}
          />
        </Item>
        <Item label="Organisation" name="organisation">
          <CompanySelect />
        </Item>
      </Form>
    </ModalComponent>
  );
};

export default ExportDateRangeModal;
