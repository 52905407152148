import { useMutation } from '@apollo/client';
import { Form, Modal } from 'antd';
import React, { useEffect } from 'react';

import { UI_LABEL_TYPES } from '../../../common/constants';
import { UPDATE_UI_LABEL } from '../graphql/Mutations';
import OrganizationUiLabelForm from './OrganizationUiLabelForm';

const OrganizationUiLabelModal = ({
  open,
  handleFormClose,
  uiLabelEdit,
  getUiLabels,
}) => {
  const [form] = Form?.useForm();

  const [updateUiLabel, { loading: updateLoading }] = useMutation(
    UPDATE_UI_LABEL,
  );

  const onCancel = () => {
    handleFormClose();
    const dataToPass = {
      key: '',
      value: '',
      type: UI_LABEL_TYPES?.[0]?.value,
    };
    form?.setFieldsValue(dataToPass);
  };

  const onFinish = async (values) => {
    const { key, ...rest } = values;
    const { id } = uiLabelEdit;
    try {
      await updateUiLabel({
        variables: { data: { ...rest }, where: { id } },
        onCompleted: () => {
          onCancel();
          getUiLabels();
        },
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (uiLabelEdit) {
      const dataToPass = {
        key: uiLabelEdit?.key,
        value: uiLabelEdit?.value,
        type: uiLabelEdit?.type,
      };
      form?.setFieldsValue(dataToPass);
    }
  }, [uiLabelEdit]);

  return (
    <>
      <Modal
        title="Edit Ui Label"
        okText="Save"
        cancelText="Cancel"
        open={open}
        onCancel={onCancel}
        onOk={form?.submit}
        okButtonProps={{ loading: updateLoading }}
      >
        <OrganizationUiLabelForm form={form} onFinish={onFinish} />
      </Modal>
    </>
  );
};

export default OrganizationUiLabelModal;
