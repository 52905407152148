import React from 'react';

import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import DashboardTabs from '../components/DashboardTabs';
import Exports from '../components/Exports';
import HouseDescTable from '../components/HouseDescTable';
import StatisticsCards from '../components/StatisticsCards';

const Dashboard = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.DASHBOARD}</div>
    </Portal>
    <DashboardTabs>
      <Exports />
      <div className="site-statistic-demo-card">
        <StatisticsCards />
      </div>
      <div className="site-statistic-demo-card">
        <HouseDescTable />
      </div>
    </DashboardTabs>
  </>
);

export default Dashboard;
