import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import HouseTypes from './pages/HouseTypes';
import './houseTypes.less';
import CreateHouseTypes from './pages/CreateHouseTypes';

const HouseTypesWrapper = () => (
  <>
    <Switch>
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}`}
        render={(props) => <HouseTypes {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}/add`}
        render={(props) => <CreateHouseTypes {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}/:mode/:id`}
        render={(props) => <CreateHouseTypes {...props} />}
      />
    </Switch>
  </>
);

export default HouseTypesWrapper;
