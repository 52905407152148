import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

import LoaderComponent from './LoaderComponent';

const ModalComponent = (props) => {
  const {
    centered = true,
    maskClosable = false,
    loading,
    children,
    ...rest
  } = props;

  const closeIcon = <CloseCircleOutlined />;
  return (
    <Modal
      className="modal"
      closeIcon={closeIcon}
      centered={centered} // centered modal
      maskClosable={maskClosable} // whether to close the modal when clicked outside the modal
      {...rest}
    >
      {loading ? <LoaderComponent /> : <>{children}</>}
    </Modal>
  );
};

export default ModalComponent;
