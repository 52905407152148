import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';

const { Item } = Form;
const {
  whitespaceNotAllowed,
  required,
  minConfigRule,
  maxConfigRule,
  defaultValConfigRule,
  stepConfigRule,
} = formValidatorRules;

const MortgageConfigModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  organizationConfig,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [minConfig, setMinConfig] = useState();
  const [maxConfig, setMaxConfig] = useState();

  useEffect(() => {
    form?.setFieldsValue(organizationConfig);
    setMinConfig(organizationConfig?.min);
    setMaxConfig(organizationConfig?.max);
  }, [organizationConfig]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setMinConfig();
    setMaxConfig();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Config"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        centered={false}
      >
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item name="key" label="Key">
            <Input allowClear placeholder="Enter Key" disabled />
          </Item>
          <Item
            name="label"
            label="Label"
            rules={[
              { ...required, message: 'Please Enter Label!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Label" maxLength={50} />
          </Item>
          <Item
            name="min"
            label="Minimum"
            rules={[
              {
                required: true,
                message: 'Please Enter Minimum!',
              },
              minConfigRule(minConfig, maxConfig),
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Minimum"
              isFractional={false}
              onChange={(e) => setMinConfig(e?.target?.value)}
            />
          </Item>
          <Item
            name="max"
            label="Maximum"
            rules={[
              {
                required: true,
                message: 'Please Enter Maximum!',
              },
              maxConfigRule(minConfig, maxConfig),
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Maximum"
              isFractional={false}
              onChange={(e) => setMaxConfig(e?.target?.value)}
            />
          </Item>
          <Item
            name="defaultValue"
            label="Default Value"
            rules={[
              {
                required: true,
                message: 'Please Enter Default Value!',
              },
              defaultValConfigRule(minConfig, maxConfig),
            ]}
          >
            <NumberComponent allowClear placeholder="Enter Default Value" />
          </Item>
          <Item
            name="step"
            label="Step"
            rules={[
              {
                required: true,
                message: 'Please Enter Step!',
              },
              stepConfigRule(minConfig, maxConfig),
            ]}
          >
            <NumberComponent allowClear placeholder="Enter Step" />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default MortgageConfigModal;
