import React from 'react';

import OrganizationFieldConfigForm from '../components/OrganizationFieldConfigForm';
import OrganizationTab from '../components/OrganizationTab';

const OrganizationFieldConfig = () => (
  <OrganizationTab>
    <OrganizationFieldConfigForm />
  </OrganizationTab>
);

export default OrganizationFieldConfig;
