import React from 'react';

import CustomisationConfigTable from './CustomisationConfigTable';
import MeasuresConfigTable from './MeasuresConfigTable';
import MortgageConfigTable from './MortgageConfigTable';
import RecommendationConfigTable from './RecommendationConfigTable';

const OrganizationFieldConfigForm = () => (
  <>
    <div className="mb-40">
      <h3>Measures Config</h3>
      <MeasuresConfigTable />
    </div>
    <div className="mb-40">
      <h3>Home Result Config</h3>
      <RecommendationConfigTable />
    </div>
    <div className="mb-40">
      <h3>Mortgage Filter Config</h3>
      <MortgageConfigTable />
    </div>
    <div className="mb-40">
      <h3>Customisation Panel Config</h3>
      <CustomisationConfigTable />
    </div>
  </>
);

export default OrganizationFieldConfigForm;
