import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '@context';
import { formValidatorRules, getBase64, validateImage } from '@common/utils';
import CommonPreview from '@components/CommonPreview';

const { required, name, email } = formValidatorRules;

const ProfileForm = ({
  form,
  setIsDisabled,
  onFinish,
  initialValues,
  userProfilePic,
  setUserProfilePic,
}) => {
  const { dispatch } = useContext(AppContext);

  const [visible, setVisible] = useState(false);
  const [profileImage, setProfileImage] = useState();

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue(initialValues);
    };
    setTimeout(initialValueFun, 300);
  }, []);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const handleChange = (info) => {
    validateImage(info, form, setUserProfilePic, 'profileImage', true);
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setProfileImage(fileObj?.url || preview);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={profileImage}
        setImage={setProfileImage}
      />
      <Form
        form={form}
        className="sticky-action-form"
        onFieldsChange={handleShowPrompt}
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="card-body-wrapper">
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={24} xl={24}>
              <Form.Item name="profileImage" label="Profile Photo">
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  maxCount={1}
                  fileList={userProfilePic}
                  beforeUpload={() => false}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  className="avatar-uploader"
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                  }}
                >
                  {userProfilePic?.length === 0 && <PlusOutlined />}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { ...required, message: 'Please Enter Your First Name' },
                  name,
                ]}
              >
                <Input
                  allowClear
                  placeholder="Enter First Name"
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { ...required, message: 'Please Enter Your Last Name' },
                  name,
                ]}
              >
                <Input
                  allowClear
                  placeholder="Enter Last Name"
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="email"
                label="Email Id"
                rules={[
                  { ...required, message: 'Please Enter Your Email' },
                  email,
                ]}
              >
                <Input disabled placeholder="Enter Email Id" maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default ProfileForm;
