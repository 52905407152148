import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@assets/svg/pen-to-square-solid.svg';

import { BREAKPOINTS } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import { UPDATE_ORGANIZATION_CONFIG } from '../graphql/Mutations';
import { GET_ORGANIZATION_MORTGAGE_LOAN_CONFIG } from '../graphql/Queries';
import MortgageConfigModal from './MortgageConfigModal';

const MortgageConfigTable = () => {
  const [form] = Form.useForm();
  const { id: organizationId } = useParams();
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [organizationConfig, setOrganizationConfig] = useState({});
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isConfigBtnLoading, setIsConfigBtnLoading] = useState(false);

  const [getCompanyConfig] = useLazyQuery(
    GET_ORGANIZATION_MORTGAGE_LOAN_CONFIG,
    {
      onCompleted: (response) => {
        setOrganizationDetails(response?.getCompanyConfig);
        setIsLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateOrganizationConfig] = useMutation(UPDATE_ORGANIZATION_CONFIG, {
    onError: () => {},
  });

  useEffect(() => {
    setIsLoading(true);
    getCompanyConfig({
      variables: { where: { companyId: organizationId } },
    });
  }, []);

  const data = organizationDetails?.mortgageLoan?.map(
    ({ __typename, ...rest }) => rest,
  );

  const onChange = async (checked, record) => {
    const updatedConfig = { ...record, visibility: checked };
    const updatedArray = data?.map((item) => {
      if (item?.key === updatedConfig?.key) {
        return updatedConfig;
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          mortgageLoan: updatedArray,
        },
      },
    });
    if (res?.data) {
      setIsLoading(true);
      getCompanyConfig({
        variables: { where: { companyId: organizationId } },
      });
    }
  };

  const onFinish = async (values) => {
    setIsConfigBtnLoading(true);
    const labelUpdatedArray = data?.map((item) => {
      if (item?.key === organizationConfig?.key) {
        return {
          ...organizationConfig,
          label: values?.label,
          min: parseFloat(values?.min),
          max: parseFloat(values?.max),
          defaultValue: parseFloat(values?.defaultValue),
          step: parseFloat(values?.step),
        };
      }
      return item;
    });
    const res = await updateOrganizationConfig({
      variables: {
        where: { id: organizationDetails?.id },
        data: {
          mortgageLoan: labelUpdatedArray,
        },
      },
    });
    setIsConfigBtnLoading(false);
    if (res?.data) {
      form?.resetFields();
      setIsConfigModalOpen(false);
      setIsLoading(true);
      getCompanyConfig({
        variables: { where: { companyId: organizationId } },
      });
    }
  };

  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'LABEL',
      dataIndex: 'label',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'VISIBILITY',
      dataIndex: 'visibility',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Switch
          defaultChecked={record?.visibility}
          size="small"
          onChange={(checked) => onChange(checked, record)}
        />
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      render: (_, record) => (
        <div className="action-button">
          <Tooltip title="Edit Ui Label" placement="top" zIndex={4}>
            <EditIcon
              className="mr-16 pointer svg-icon"
              onClick={() => {
                setOrganizationConfig(record);
                setIsConfigModalOpen(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <MortgageConfigModal
        form={form}
        loadings={isConfigBtnLoading}
        isModalOpen={isConfigModalOpen}
        setIsModalOpen={setIsConfigModalOpen}
        organizationConfig={organizationConfig}
        onFinish={onFinish}
      />
      <CommonTable
        columns={columns}
        data={data || []}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record?.key}
      />
    </>
  );
};

export default MortgageConfigTable;
