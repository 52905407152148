import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';

import { MODULES, ROUTES, DEFAULT_SORT_CONFIG } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import history from '../../../historyData';
import RoofExtensionsTable from '../components/RoofExtensionsTable';
import { LIST_ROOF_EXTENSIONS } from '../graphql/Queries';
import { DELETE_ROOF_EXTENSION } from '../graphql/Mutations';

const RoofExtensions = () => {
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentRoofExtension, setCurrentRoofExtension] = useState(null);

  const [
    listRoofExtensions,
    { data: roofExtensionsData, loading: dataLoading },
  ] = useLazyQuery(LIST_ROOF_EXTENSIONS);

  const [deleteRoofExtension, { loading: deleteLoading }] = useMutation(
    DELETE_ROOF_EXTENSION,
  );

  const getRoofExtensions = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listRoofExtensions({
        variables: {
          filter: {
            skip: (skip - 1) * limit,
            limit,
            search,
            isActive:
              isActiveFilter !== null ? JSON?.parse(isActiveFilter) : null,
          },
          sort: sortConfig?.sortOn ? [{ ...sortConfig }] : [],
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const handleFilterApply = () => {
    getRoofExtensions();
    setFilterVisible(false);
  };

  useEffect(() => {
    if (isActiveFilter === null) {
      handleFilterApply();
    }
  }, [isActiveFilter]);

  useEffect(() => {
    getRoofExtensions();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteModalOpen = (value) => {
    setIsDeleteOpen(true);
    setCurrentRoofExtension({ ...value });
  };

  const handleDeleteRoofExtension = async (id) => {
    try {
      const res = await deleteRoofExtension({ variables: { where: { id } } });
      if (res?.data) {
        setCurrentRoofExtension(null);
        setIsDeleteOpen(false);
        getRoofExtensions();
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <CommonHeader
        heading={MODULES?.ROOF_EXTENSIONS}
        customLink={ROUTES?.MASTER}
      >
        <Button
          className="common-button"
          size="middle"
          icon={<PlusOutlined />}
          onClick={() =>
            history?.push(`${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}/add`)
          }
        >
          Add Roof Extension
        </Button>
      </CommonHeader>
      <Card className="full-height-without-nav">
        <RoofExtensionsTable
          handleTableChange={handleTableChange}
          paginationConfig={paginationConfig}
          sortConfig={sortConfig}
          roofExtensionsData={roofExtensionsData?.adminRoofExtensions}
          loading={dataLoading}
          onSearchChange={onSearchChange}
          handleDeleteModalOpen={handleDeleteModalOpen}
          isActiveFilter={isActiveFilter}
          setIsActiveFilter={setIsActiveFilter}
          handleFilterApply={handleFilterApply}
          filterVisible={filterVisible}
          setFilterVisible={setFilterVisible}
        />
      </Card>
      <Modal
        title="Delete the Roof Extension"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteRoofExtension(currentRoofExtension?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentRoofExtension(null);
        }}
      >
        Are you sure you want to delete this roof extension?
      </Modal>
    </>
  );
};

export default RoofExtensions;
