import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SIGNED_URL = gql`
  query($data: GetRoofExtensionUploadSignedUrlInput!) {
    getRoofExtensionUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const LIST_ROOF_EXTENSIONS = gql`
  query(
    $sort: [ListRoofExtensionSort!]
    $filter: AdminListRoofExtensionFilter
  ) {
    adminRoofExtensions(sort: $sort, filter: $filter) {
      count
      data {
        id
        description
        createdAt
        imageUrl
        isActive
        key
        tooltip {
          title
          body
        }
        label
      }
    }
  }
`;

export const GET_ROOF_EXTENSIONS = gql`
  query($where: RoofExtensionWhereUniqueInput!) {
    roofExtension(where: $where) {
      data {
        id
        description
        imageUrl
        isActive
        key
        tooltip {
          title
          body
        }
        label
      }
    }
  }
`;
