import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EXPORT_JOBS = gql`
  query ExportJobs($filters: ExportJobsFilters, $sort: [ExportJobSort]) {
    exportJobs(filters: $filters, sort: $sort) {
      count
      data {
        createdAt
        fileUrl
        id
        status
        type
      }
    }
  }
`;
