import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ROOF_EXTENSION = gql`
  mutation($data: CreateRoofExtensionInput!) {
    createRoofExtension(data: $data) {
      message
    }
  }
`;

export const UPDATE_ROOF_EXTENSION = gql`
  mutation(
    $where: RoofExtensionWhereUniqueInput!
    $data: UpdateRoofExtensionInput!
  ) {
    updateRoofExtension(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_ROOF_EXTENSION = gql`
  mutation($where: RoofExtensionWhereUniqueInput!) {
    deleteRoofExtension(where: $where) {
      message
    }
  }
`;
