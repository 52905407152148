import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import RoofExtensions from './pages/RoofExtensions';
import CreateRoofExtensions from './pages/CreateRoofExtensions';

const RoofExtensionsWrapper = () => (
  <>
    <Switch>
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}`}
        render={(props) => <RoofExtensions {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}/:type/add`}
        render={(props) => <CreateRoofExtensions {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}/:type/:mode/:id`}
        render={(props) => <CreateRoofExtensions {...props} />}
      />
    </Switch>
  </>
);

export default RoofExtensionsWrapper;
