import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROFILE_IMAGE_UPLOAD_SIGNED_URL = gql`
  query GetProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getProfileImageUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;
