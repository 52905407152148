import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { whitespaceNotAllowed, required } = formValidatorRules;

const ConfigModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  organizationConfig,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  useEffect(() => {
    form?.setFieldsValue({
      key: organizationConfig?.key,
      label: organizationConfig?.label,
    });
  }, [organizationConfig]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Config"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        centered={false}
      >
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item name="key" label="Key">
            <Input allowClear placeholder="Enter Key" disabled />
          </Item>
          <Item
            name="label"
            label="Label"
            rules={[
              { ...required, message: 'Please Enter Label!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Label" maxLength={50} />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default ConfigModal;
