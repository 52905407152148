import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from '../../common/constants';
import './exports.less';
import Exports from './pages/Exports';

const ExportsWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.EXPORTS}
      render={(props) => <Exports {...props} />}
    />
  </Switch>
);

export default ExportsWrapper;
