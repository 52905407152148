import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ROOF_TYPE = gql`
  mutation($data: CreateRoofTypeInput!) {
    createRoofType(data: $data) {
      message
    }
  }
`;

export const UPDATE_ROOF_TYPE = gql`
  mutation($data: UpdateRoofTypeInput!, $where: RoofTypeWhereUniqueInput!) {
    updateRoofType(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_ROOF_TYPE = gql`
  mutation($where: RoofTypeWhereUniqueInput!) {
    deleteRoofType(where: $where) {
      message
    }
  }
`;
