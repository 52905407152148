import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_MATERIAL_CATEGORY = gql`
  mutation($data: CreateMaterialCategoryInput!) {
    createMaterialCategory(data: $data) {
      message
    }
  }
`;

export const UPDATE_MATERIAL_CATEGORY = gql`
  mutation(
    $data: UpdateMaterialCategoryInput!
    $where: MaterialCategoryWhereUniqueInput!
  ) {
    updateMaterialCategory(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_MATERIAL_CATEGORY = gql`
  mutation($where: MaterialCategoryWhereUniqueInput!) {
    deleteMaterialCategory(where: $where) {
      message
    }
  }
`;

export const CREATE_MATERIAL = gql`
  mutation($data: CreateMaterialInput!) {
    createMaterial(data: $data) {
      message
    }
  }
`;

export const UPDATE_MATERIAL = gql`
  mutation($data: UpdateMaterialInput!, $where: MaterialWhereUniqueInput!) {
    updateMaterial(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_MATERIAL = gql`
  mutation($where: MaterialWhereUniqueInput!) {
    deleteMaterial(where: $where) {
      message
    }
  }
`;

export const CREATE_MATERIAL_QUESTION = gql`
  mutation($data: CreateMaterialQuestionInput!) {
    createMaterialQuestion(data: $data) {
      message
    }
  }
`;

export const UPDATE_MATERIAL_QUESTION = gql`
  mutation(
    $data: UpdateMaterialQuestionInput!
    $where: MaterialQuestionWhereUniqueInput!
  ) {
    updateMaterialQuestion(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_MATERIAL_QUESTION = gql`
  mutation($where: MaterialQuestionWhereUniqueInput!) {
    deleteMaterialQuestion(where: $where) {
      message
    }
  }
`;
