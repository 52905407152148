import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@common/constants';

import HouseShapes from './pages/HouseShapes';
import CreateHouseShapes from './pages/CreateHouseShapes';

const HouseShapesWrapper = () => (
  <>
    <Switch>
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}`}
        render={(props) => <HouseShapes {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}/add`}
        render={(props) => <CreateHouseShapes {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}/:mode/:id`}
        render={(props) => <CreateHouseShapes {...props} />}
      />
    </Switch>
  </>
);

export default HouseShapesWrapper;
