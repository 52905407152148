import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_MATERIAL_CATEGORIES = gql`
  query(
    $sort: [ListMaterialCategorySort!]
    $filter: AdminListMaterialCategoryFilter
  ) {
    adminMaterialCategories(sort: $sort, filter: $filter) {
      count
      data {
        createdAt
        description
        id
        isActive
        imageUrl
        key
        tooltip {
          title
          body
        }
        label
        required
      }
    }
  }
`;

export const GET_MATERIAL_CATEGORIES = gql`
  query($where: MaterialCategoryWhereUniqueInput!) {
    materialCategory(where: $where) {
      data {
        description
        isActive
        imageUrl
        key
        tooltip {
          title
          body
        }
        label
        required
      }
    }
  }
`;

export const LIST_MATERIALS = gql`
  query(
    $where: ListMaterialsWhere!
    $sort: [ListMaterialSort!]
    $filter: AdminListMaterialFilter
  ) {
    adminMaterials(where: $where, sort: $sort, filter: $filter) {
      data {
        createdAt
        description
        id
        imageUrl
        isActive
        tooltip {
          title
          body
        }
        key
        label
      }
    }
  }
`;

export const GET_MATERIALS = gql`
  query($where: MaterialWhereUniqueInput!) {
    material(where: $where) {
      data {
        id
        description
        imageUrl
        isActive
        key
        tooltip {
          title
          body
        }
        label
      }
    }
  }
`;

export const GET_SIGNED_URL_CATEGORY = gql`
  query($data: GetMaterialCategoryUploadSignedUrlInput!) {
    getMaterialCategoryUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query($data: GetMaterialUploadSignedUrlInput!) {
    getMaterialUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const LIST_MATERIAL_QUESTION = gql`
  query(
    $where: AdminMaterialQuestionsWhereInput!
    $sort: [ListMaterialQuestionSort!]
    $filter: AdminListMaterialQuestionFilter
  ) {
    adminMaterialQuestions(where: $where, sort: $sort, filter: $filter) {
      data {
        required
        question
        type
        isActive
        id
        key
        tooltip {
          title
          body
        }
        description
        createdAt
        options
      }
    }
  }
`;
