import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { AppContext } from '@context';
import { getSignedUrl, fileUpload } from '@common/utils';
import CommonHeader from '@components/CommonHeader';
import { ROUTES } from '@common/constants';

import MaterialCategoriesForm from '../components/MaterialCategoriesForm';
import {
  CREATE_MATERIAL_CATEGORY,
  UPDATE_MATERIAL_CATEGORY,
} from '../graphql/Mutations';
import {
  GET_MATERIAL_CATEGORIES,
  GET_SIGNED_URL_CATEGORY,
} from '../graphql/Queries';
import history from '../../../historyData';
import MaterialTab from '../components/MaterialTabs';

const CreateMaterialCategories = () => {
  const { mode, id } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);
  const [materialCategoryLogo, setMaterialCategoryLogo] = useState([]);

  const [createMaterialCategory, { loading: createLoading }] = useMutation(
    CREATE_MATERIAL_CATEGORY,
  );

  const [updateMaterialCategory, { loading: updateLoading }] = useMutation(
    UPDATE_MATERIAL_CATEGORY,
  );

  const [getMaterialCategory, { data: materialCategoryData }] = useLazyQuery(
    GET_MATERIAL_CATEGORIES,
  );

  const fetchMaterialCategory = async () => {
    try {
      await getMaterialCategory({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (id) {
      fetchMaterialCategory();
    }
  }, []);

  const onEdit = () => {
    setIsView(false);
    history?.push(`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/edit/${id}`);
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const { isActive, required, tooltipTitle, tooltipBody, ...rest } = values;

    const dataToSend = {
      ...rest,
      tooltip: { title: tooltipTitle, body: tooltipBody },
      isActive: JSON.parse(isActive),
      required: JSON.parse(required),
    };
    try {
      let imgKey;
      if (values?.imageUrl && !materialCategoryLogo?.[0]?.url) {
        await getSignedUrl(
          values?.imageUrl?.[0]?.originFileObj,
          GET_SIGNED_URL_CATEGORY,
        )?.then(async (res) => {
          const signedImageUrl =
            res?.getMaterialCategoryUploadSignedUrl?.signedUrl;
          imgKey = res?.getMaterialCategoryUploadSignedUrl?.key;
          await fileUpload(
            signedImageUrl,
            values?.imageUrl?.[0]?.originFileObj,
          );
          dataToSend.imageUrl = imgKey;
        });
      } else if (materialCategoryLogo?.[0]?.url) {
        delete dataToSend.imageUrl;
      }
      if (id) {
        try {
          await updateMaterialCategory({
            variables: {
              where: { id },
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createMaterialCategory({
            variables: {
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (materialCategoryData?.materialCategory?.data) {
      const dataToPass = {
        key: materialCategoryData?.materialCategory?.data?.key,
        label: materialCategoryData?.materialCategory?.data?.label,
        description: materialCategoryData?.materialCategory?.data?.description,
        isActive: JSON.stringify(
          materialCategoryData?.materialCategory?.data?.isActive,
        ),
        tooltipTitle:
          materialCategoryData?.materialCategory?.data?.tooltip?.title,
        tooltipBody:
          materialCategoryData?.materialCategory?.data?.tooltip?.body,
        required: JSON.stringify(
          materialCategoryData?.materialCategory?.data?.required,
        ),
        imageUrl: materialCategoryData?.materialCategory?.data?.imageUrl,
      };
      form?.setFieldsValue(dataToPass);
      setMaterialCategoryLogo([
        { url: materialCategoryData?.materialCategory?.data?.imageUrl },
      ]);
    }
  }, [materialCategoryData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <CommonHeader
        heading={id ? `${mode} Material Category` : 'Add Material Category'}
        customLink={ROUTES?.MASTER}
      >
        {isView ? (
          <Button className="common-button" size="middle" onClick={onEdit}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              type="primary"
              className="common-button mr-16"
              size="middle"
              onClick={onCancelEdit}
            >
              Cancel
            </Button>
            <Button
              className="common-button"
              size="middle"
              onClick={form?.submit}
              loading={createLoading || updateLoading}
              disabled={createLoading || updateLoading || isDisabled}
            >
              Save
            </Button>
          </>
        )}
      </CommonHeader>
      <MaterialTab>
        <MaterialCategoriesForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          isView={isView}
          materialCategoryLogo={materialCategoryLogo}
          setMaterialCategoryLogo={setMaterialCategoryLogo}
        />
      </MaterialTab>
    </>
  );
};

export default CreateMaterialCategories;
