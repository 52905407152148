import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTES, TABS } from '@common/constants';

const DashboardTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(TABS?.GENERAL?.key);

  const tabList = [
    {
      key: TABS?.GENERAL?.key,
      label: TABS?.GENERAL?.label,
      children,
    },
    {
      key: TABS?.VISUALISATION?.key,
      label: TABS?.VISUALISATION?.label,
      children,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.GENERAL)) {
      setActiveTabKey(TABS?.GENERAL?.key);
    }
    if (location?.pathname?.includes(ROUTES?.VISUALISATION)) {
      setActiveTabKey(TABS?.VISUALISATION?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.GENERAL?.key:
        history?.push(ROUTES?.MAIN);
        break;
      case TABS?.VISUALISATION?.key:
        history?.push(ROUTES?.VISUALISATION);
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={tabList}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default DashboardTabs;
