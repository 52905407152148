import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '@common/constants';
import ProfileWrapper from '@modules/profile';

import Error404 from '../Error404';
import DashboardWrapper from '../modules/dashboard';
import ExportsWrapper from '../modules/exports';
import HeatingSystemsWrapper from '../modules/heatingSystems';
import HouseShapesWrapper from '../modules/houseShapes';
import HouseTypesWrapper from '../modules/houseTypes';
import MaterialCategoriesWrapper from '../modules/materialCategories';
import OrganizationWrapper from '../modules/organizations';
import RoofExtensionsWrapper from '../modules/roofExtensions';
import RoofTypesWrapper from '../modules/roofTypes';

const ContentRoutes = () => (
  <Switch>
    <Route path={ROUTES?.PROFILE} exact component={ProfileWrapper} />
    <Route path={ROUTES?.ORGANIZATIONS} component={OrganizationWrapper} />
    <Route
      path={`${ROUTES?.MASTER}`}
      exact
      render={() => <Redirect to={`${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}`} />}
    />
    <Route
      path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_TYPES}`}
      component={HouseTypesWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER}${ROUTES?.HOUSE_SHAPES}`}
      component={HouseShapesWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}`}
      component={RoofTypesWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}`}
      component={RoofExtensionsWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}`}
      component={MaterialCategoriesWrapper}
    />
    <Route
      path={`${ROUTES?.MASTER}${ROUTES?.HEATING_SYSTEMS}`}
      component={HeatingSystemsWrapper}
    />
    <Route path={ROUTES?.EXPORTS} exact component={ExportsWrapper} />
    <Route path={ROUTES?.MAIN} component={DashboardWrapper} />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ContentRoutes;
