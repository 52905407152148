import React from 'react';
import { Button, Col, Form, Input, Radio, Row, Select, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';

import { QUESTION_TYPE } from '@common/constants';
import { formValidatorRules } from '@common/utils';
import 'react-quill/dist/quill.snow.css';

const MaterialQuestionsForm = ({
  form,
  onFinish,
  handleShowPrompt,
  isView,
}) => {
  const { Item } = Form;
  const { required } = formValidatorRules;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        className={`${isView ? 'common-view-form' : 'form'} master-form`}
        initialValues={{
          isActive: 'false',
          options: [{}],
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Key"
              name="key"
              rules={[{ ...required, message: 'Please Enter Key!' }]}
            >
              <Input placeholder="Enter Key" maxLength={50} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Question"
              name="question"
              rules={[{ ...required, message: 'Please Enter Question!' }]}
            >
              <Input placeholder="Enter Question" maxLength={50} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Type"
              name="type"
              rules={[{ ...required, message: 'Please Select Type!' }]}
            >
              <Select options={QUESTION_TYPE} placeholder="Select Type" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Is Active"
              name="isActive"
              rules={[{ ...required, message: 'Please Enter Is Active!' }]}
            >
              <Radio.Group>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Radio.Group>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Options">
              <Form.List name="options">
                {(field, { add, remove }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 16,
                    }}
                  >
                    {field.map((subField) => (
                      <Space key={subField.key}>
                        <Item noStyle name={[subField.name, 'optionInput']}>
                          <Input placeholder="Enter Option" />
                        </Item>
                        <CloseOutlined
                          onClick={() => {
                            remove(subField.name);
                          }}
                        />
                      </Space>
                    ))}
                    <Button type="dashed" size="small" onClick={add} block>
                      Add More Option
                    </Button>
                  </div>
                )}
              </Form.List>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Description" name="description">
              <Input.TextArea
                rows={4}
                placeholder="Enter Description"
                maxLength={1000}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Tooltip Title" name="tooltipTitle">
              <Input placeholder="Enter Label" maxLength={255} />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Tooltip Body" name="tooltipBody">
              <ReactQuill
                readOnly={!!isView}
                theme="snow"
                placeholder="Enter Tooltip Body"
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MaterialQuestionsForm;
