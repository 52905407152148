import { EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row, Statistic, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  BREAKPOINTS,
  DATE_FORMATS,
  GUTTER_VARIATIONS,
  REGEX,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import {
  DASHBOARD_COUNTS,
  DASHBOARD_MOST_LIKED_RECOMMENDATIONS,
} from '../graphql/Queries';
import CompanySelect from './CompanySelect';
import ViewLikedRecommendations from './ViewLikedRecommendations';

const { RangePicker } = DatePicker;

const StatisticsCards = () => {
  const [isRecommendationsModalOpen, setIsRecommendationsModalOpen] = useState(
    false,
  );
  const [statistics, setStatistics] = useState({});
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);
  const [likedRecommendationCount, setLikedRecommendationCount] = useState();
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [emails, setEmails] = useState([]);

  const [dashboardCounts] = useLazyQuery(DASHBOARD_COUNTS, {
    onCompleted: (response) => {
      setStatistics(response?.dashboardCounts);
      setIsStatisticsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [dashboardMostLikedRecommendations] = useLazyQuery(
    DASHBOARD_MOST_LIKED_RECOMMENDATIONS,
    {
      onCompleted: (response) => {
        setLikedRecommendationCount(
          response?.dashboardMostLikedRecommendations?.count,
        );
        setIsStatisticsLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setIsStatisticsLoading(true);
    const filters = {
      dateRange:
        selectedDateRange?.from && selectedDateRange?.to
          ? {
              from: selectedDateRange?.from,
              to: selectedDateRange?.to,
            }
          : undefined,
      organizationId: selectedCompany || undefined,
      excludeEmails: emails,
    };
    dashboardCounts({
      variables: {
        filters,
      },
    });
    dashboardMostLikedRecommendations({
      variables: {
        filters,
      },
    });
  }, [selectedCompany, selectedDateRange, emails]);

  const onRangePickerChange = (values) => {
    const rangeObj = {
      from: values?.[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };
    setSelectedDateRange(values ? rangeObj : {});
  };

  const emailValidator = (values) => {
    const invalidInputs = values?.filter(
      (value) => !value?.match(REGEX?.EMAIL),
    );
    setInvalidEmails(invalidInputs);
    setEmails(values);
  };

  const onPreventMouseDown = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <>
      <ViewLikedRecommendations
        isModalOpen={isRecommendationsModalOpen}
        setIsModalOpen={setIsRecommendationsModalOpen}
      />
      <div className="d-flex justify-end mb-24">
        <CompanySelect
          className="filter-select mr-16"
          onChange={(value) => setSelectedCompany(value)}
        />
        <SelectComponent
          mode="tags"
          placeholder="Enter Exclude Emails"
          className="filter-select mr-16"
          tokenSeparators={[',', ' ']}
          popupClassName="select-email-dropdown"
          onChange={(values) => emailValidator(values)}
          tagRender={({ label, value, closable, onClose }) => (
            <Tag
              closable={closable}
              onClose={(e) => {
                e?.preventDefault();
                onClose();
              }}
              onMouseDown={onPreventMouseDown}
              color={invalidEmails?.includes(value) ? 'red' : ''}
            >
              {label}
            </Tag>
          )}
        />
        <RangePicker
          popupClassName="picker-with-scroll"
          format={DATE_FORMATS?.REGULAR}
          onChange={onRangePickerChange}
          disabledDate={(current) =>
            current && current?.startOf('day') > moment()?.startOf('day')
          }
          placeholder={['From Date', 'To Date']}
        />
      </div>
      {isStatisticsLoading ? (
        <LoaderComponent setHeight={30} />
      ) : (
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Users"
                  value={statistics?.totalUsers || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of users till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Users With Recommendation"
                  value={statistics?.totalUsersWithRecommendation || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of users with recommendation till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Users Without Recommendation"
                  value={statistics?.totalUsersWithoutRecommendation || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of users without recommendation till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total New Users"
                  value={statistics?.totalNewUsers || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of new users till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Existing Users"
                  value={statistics?.totalExistingUser || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of existing users till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Subscribed Users"
                  value={statistics?.totalSubscribedUsers || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of subscribed users who opted for email marketing tick box"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Recommendation Generated"
                  value={statistics?.totalRecommendationsGenerated || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of recommendation generated till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Measures Generated"
                  value={statistics?.totalMeasuresGenerated || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of measures generated till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Liked Measures"
                  value={likedRecommendationCount || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container d-flex">
                  <EyeOutlined
                    className="info-icon pointer mr-8"
                    onClick={() => setIsRecommendationsModalOpen(true)}
                  />
                  <Tooltip
                    title="The total number of liked measures till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Carbon (Current Result)"
                  value={`${statistics?.totalCarbonHomeResult || 0}t`}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total ton of carbon (current result) till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Carbon (Future Result)"
                  value={`${
                    statistics?.totalCarbonLikedMeasuresHomeResult || 0
                  }t`}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total ton of carbon (future result) when measures are liked till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Carbon Saved (Future Result)"
                  value={`${statistics?.totalCarbonSaved || 0}t`}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total ton of carbon saved (future result) till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Cost Savings (Current result)"
                  value={`£${statistics?.totalCostCurrentResult || 0}`}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total cost savings (current result) till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Cost Savings (Future result)"
                  value={`£${statistics?.totalCostFutureResult || 0}`}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total cost savings (future result) till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Cost Saved (Future Result)"
                  value={`£${statistics?.totalCostSaving || 0}`}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total cost saved (future results) till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Card className="statistic-card">
              <div className="info-card-container">
                <Statistic
                  title="Total Upgrades Selected"
                  value={statistics?.totalUpgradeSelected || 0}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The total number of upgrades selected till date"
                    trigger="click"
                    className="pointer"
                    placement={
                      // eslint-disable-next-line no-undef
                      window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                    }
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StatisticsCards;
