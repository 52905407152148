import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { AppContext } from '@context';
import { getSignedUrl, fileUpload } from '@common/utils';
import { ROUTES } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import MaterialsForm from '../components/MaterialsForm';
import { CREATE_MATERIAL, UPDATE_MATERIAL } from '../graphql/Mutations';
import { GET_MATERIALS, GET_SIGNED_URL } from '../graphql/Queries';
import history from '../../../historyData';

const CreateMaterials = () => {
  const { mode, id, materialId } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);
  const [materialLogo, setMaterialLogo] = useState([]);

  const [createMaterial, { loading: createLoading }] = useMutation(
    CREATE_MATERIAL,
  );

  const [updateMaterial, { loading: updateLoading }] = useMutation(
    UPDATE_MATERIAL,
  );

  const [getMaterial, { data: materialData }] = useLazyQuery(GET_MATERIALS);

  const fetchMaterial = async () => {
    try {
      await getMaterial({
        variables: { where: { id: materialId } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (materialId) {
      fetchMaterial();
    }
  }, []);

  const onEdit = () => {
    setIsView(false);
    history?.push(
      `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}${ROUTES?.MATERIALS}/${id}/edit/${materialId}`,
    );
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(
      `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/${mode}${ROUTES?.MATERIALS}/${id}`,
    );
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const { isActive, tooltipTitle, tooltipBody, ...rest } = values;

    const dataToSend = {
      ...rest,
      tooltip: { title: tooltipTitle, body: tooltipBody },
      isActive: JSON.parse(isActive),
    };

    try {
      let imgKey;
      if (values?.imageUrl && !materialLogo?.[0]?.url) {
        await getSignedUrl(
          values?.imageUrl?.[0]?.originFileObj,
          GET_SIGNED_URL,
        )?.then(async (res) => {
          const signedImageUrl = res?.getMaterialUploadSignedUrl?.signedUrl;
          imgKey = res?.getMaterialUploadSignedUrl?.key;
          await fileUpload(
            signedImageUrl,
            values?.imageUrl?.[0]?.originFileObj,
          );
          dataToSend.imageUrl = imgKey;
        });
      } else if (materialLogo?.[0]?.url) {
        delete dataToSend.imageUrl;
      }
      if (materialId) {
        try {
          await updateMaterial({
            variables: {
              where: { id: materialId },
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createMaterial({
            variables: {
              data: {
                ...dataToSend,
                materialCategoryId: id,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (materialData?.material?.data) {
      const dataToPass = {
        key: materialData?.material?.data?.key,
        label: materialData?.material?.data?.label,
        description: materialData?.material?.data?.description,
        imageUrl: materialData?.material?.data?.imageUrl,
        isActive: JSON.stringify(materialData?.material?.data?.isActive),
        tooltipTitle: materialData?.material?.data?.tooltip?.title,
        tooltipBody: materialData?.material?.data?.tooltip?.body,
      };
      form?.setFieldsValue(dataToPass);
      setMaterialLogo([{ url: materialData?.material?.data?.imageUrl }]);
    }
  }, [materialData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <CommonHeader
        heading={materialId ? `${mode} Material` : `Add Material`}
        customLink={ROUTES?.MASTER}
      >
        {isView ? (
          <Button className="common-button" size="middle" onClick={onEdit}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              type="primary"
              className="common-button mr-16"
              size="middle"
              onClick={onCancelEdit}
            >
              Cancel
            </Button>
            <Button
              className="common-button"
              size="middle"
              onClick={form?.submit}
              loading={createLoading || updateLoading}
              disabled={createLoading || updateLoading || isDisabled}
            >
              Save
            </Button>
          </>
        )}
      </CommonHeader>
      <Card className="full-height-with-nav" title="Material">
        <MaterialsForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          isView={isView}
          materialLogo={materialLogo}
          setMaterialLogo={setMaterialLogo}
        />
      </Card>
    </>
  );
};

export default CreateMaterials;
