import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';

import { useMutation } from '@apollo/client';

import MaterialQuestionsForm from './MaterialQuestionsForm';
import {
  CREATE_MATERIAL_QUESTION,
  UPDATE_MATERIAL_QUESTION,
} from '../graphql/Mutations';

const MaterialQuestionsModal = ({
  open,
  onCancel,
  questionEdit,
  materialId,
  expandedRow,
  getMaterialQuestions,
}) => {
  const [form] = Form?.useForm();

  const [createMaterialQuestion, { loading: createLoading }] = useMutation(
    CREATE_MATERIAL_QUESTION,
  );

  const [updateMaterialQuestion, { loading: updateLoading }] = useMutation(
    UPDATE_MATERIAL_QUESTION,
  );

  useEffect(() => {
    if (questionEdit) {
      const optionsToPass = questionEdit?.options?.map((item) => ({
        optionInput: item,
      }));
      const dataToPass = {
        key: questionEdit?.key,
        question: questionEdit?.question,
        type: questionEdit?.type,
        description: questionEdit?.description,
        isActive: JSON.stringify(questionEdit?.isActive),
        tooltipTitle: questionEdit?.tooltip?.title,
        tooltipBody: questionEdit?.tooltip?.body,
        options: optionsToPass,
      };
      form?.setFieldsValue(dataToPass);
    }
  }, [questionEdit]);

  const handleFormClose = () => {
    const dataToPass = {
      key: '',
      question: '',
      type: null,
      options: [{}],
      description: '',
      tooltipTitle: '',
      tooltipBody: '',
      isActive: 'false',
    };
    form.setFieldsValue(dataToPass);
    onCancel();
  };

  const onFinish = async (values) => {
    const { isActive, options, tooltipTitle, tooltipBody, ...rest } = values;

    const optionToSend = [];
    options?.map((item) => optionToSend?.push(item?.optionInput));
    const dataToSend = {
      ...rest,
      options: optionToSend,
      isActive: JSON.parse(isActive),
      tooltip: { title: tooltipTitle, body: tooltipBody },
    };

    if (questionEdit) {
      try {
        await updateMaterialQuestion({
          variables: {
            where: { id: questionEdit?.id },
            data: {
              ...dataToSend,
            },
          },
          onCompleted: () => {
            getMaterialQuestions(!!expandedRow, materialId);
            handleFormClose();
          },
        });
      } catch (error) {
        return error;
      }
    } else {
      try {
        await createMaterialQuestion({
          variables: {
            data: {
              materialId,
              ...dataToSend,
            },
          },
          onCompleted: () => {
            getMaterialQuestions(!!expandedRow, materialId);
            handleFormClose();
          },
        });
      } catch (error) {
        return error;
      }
    }
  };
  return (
    <Modal
      title={`${questionEdit ? 'Edit' : 'Add'} Material Question`}
      okText="Save"
      cancelText="Cancel"
      open={open}
      onCancel={handleFormClose}
      onOk={form?.submit}
      okButtonProps={{ loading: createLoading || updateLoading }}
    >
      <MaterialQuestionsForm form={form} onFinish={onFinish} />
    </Modal>
  );
};

export default MaterialQuestionsModal;
