import React from 'react';
import { Select, Tooltip } from 'antd';

import { BREAKPOINTS, LANGUAGES } from '@common/constants';
import SearchComponent from '@components/SearchComponent';
import CommonTable from '@components/CommonTable';
import { ReactComponent as EditIcon } from '@assets/svg/pen-to-square-solid.svg';

const OrganizationUiLabelsTable = ({
  handleTableChange,
  onSearchChange,
  setIsEditModal,
  setUiLabelEdit,
  uiLabelsData = [],
  uiLabelsLoading,
  paginationConfig,
  languageFilter,
  setLanguageFilter,
}) => {
  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'VALUE',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 120,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value ?? '-',
    },
    {
      title: 'LANGUAGE',
      dataIndex: 'languageCode',
      key: 'languageCode',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value ?? '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 100,
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => (
        <div className="action-button">
          <Tooltip title="Edit Ui Label" placement="top" zIndex={4}>
            <EditIcon
              onClick={() => {
                setIsEditModal(true);
                setUiLabelEdit(value);
              }}
              className="mr-16 pointer svg-icon"
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <Select
          className="search-box"
          value={languageFilter}
          onChange={(value) => setLanguageFilter(value)}
          placeholder="Select Language"
          options={LANGUAGES}
        />
        <SearchComponent
          onChange={onSearchChange}
          className="search-box ml-16"
        />
      </div>
      <CommonTable
        columns={columns}
        data={uiLabelsData?.data}
        onChange={handleTableChange}
        paginationConfig={{
          total: uiLabelsData?.count,
          current: paginationConfig?.skip,
          pageSize: paginationConfig?.limit,
        }}
        loading={{ spinning: uiLabelsLoading, size: 'default' }}
        rowKey={(record) => record?.id}
        rowClassName="pointer"
      />
    </>
  );
};

export default OrganizationUiLabelsTable;
