import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ROUTES, TABS } from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import history from '../../../historyData';
import OrganizationTab from '../components/OrganizationTab';
import OrganizationEmailConfigForm from '../components/OrganizationEmailConfigForm';
import { CREATE_EMAIL_CONFIG, UPDATE_EMAIL_CONFIG } from '../graphql/Mutations';
import { GET_EMAIL_CONFIG } from '../graphql/Queries';

const OrganizationEmailConfig = () => {
  const { id: organizationId, mode } = useParams();
  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);

  const [createOrganizationConfig, { loading: createLoading }] = useMutation(
    CREATE_EMAIL_CONFIG,
    {
      onError: () => {},
    },
  );

  const [
    updateOrganizationConfig,
    { loading: updateLoading },
  ] = useMutation(UPDATE_EMAIL_CONFIG, { onError: () => {} });

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(`${ROUTES?.ORGANIZATIONS}`);
  };

  const [
    getOrganizationConfig,
    { data: organizationConfigData },
  ] = useLazyQuery(GET_EMAIL_CONFIG);

  const getOrganizationConfigDetail = async () => {
    try {
      await getOrganizationConfig({
        variables: { where: { companyId: organizationId } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    if (organizationConfigData?.getCompanyConfig) {
      try {
        await updateOrganizationConfig({
          variables: {
            where: { id: organizationConfigData?.getCompanyConfig?.id },
            data: { emailConfig: values },
          },
          onCompleted: () => onCancelEdit(),
        });
      } catch (error) {
        return error;
      }
    } else {
      try {
        await createOrganizationConfig({
          variables: {
            data: {
              companyId: organizationId,
              emailConfig: { ...values },
            },
          },
          onCompleted: () => onCancelEdit(),
        });
      } catch (error) {
        return error;
      }
    }
  };

  const onEdit = () => {
    setIsView(false);
    history?.push(
      `${ROUTES?.ORGANIZATIONS}/edit/${organizationId}/${TABS?.ORGANIZATION_EMAIL_CONFIG?.key}`,
    );
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationConfigDetail();
    }
    if (mode === 'view') {
      setIsView(true);
    }
  }, []);

  return (
    <>
      <CommonHeader
        heading={
          organizationConfigData?.getCompanyConfig
            ? `${mode} Email Config`
            : 'Add Email Config'
        }
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <div className="header-btn-wrapper">
          {isView ? (
            <Button className="common-button" size="middle" onClick={onEdit}>
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                onClick={onCancelEdit}
              >
                Cancel
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form?.submit}
                loading={createLoading || updateLoading}
                disabled={createLoading || updateLoading || isDisabled}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </CommonHeader>
      <OrganizationTab>
        <OrganizationEmailConfigForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          isView={isView}
          organizationConfigData={organizationConfigData?.getCompanyConfig}
        />
      </OrganizationTab>
    </>
  );
};

export default OrganizationEmailConfig;
