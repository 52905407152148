import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_EXPORT_JOB = gql`
  mutation CreateExportJob(
    $type: ExportJobType!
    $filters: DashboardCommonFilter
  ) {
    createExportJob(type: $type, filters: $filters) {
      message
    }
  }
`;
