import React from 'react';

import { Tooltip, Tag, Image } from 'antd';
import dayjs from 'dayjs';

import { BREAKPOINTS, ROUTES, DATE_FORMATS, TABS } from '@common/constants';
import CommonTable from '@components/CommonTable';
import SearchComponent from '@components/SearchComponent';
import { ReactComponent as EditIcon } from '@assets/svg/pen-to-square-solid.svg';
import { ReactComponent as DeleteIcon } from '@assets/svg/trash-solid.svg';
import { ReactComponent as ActiveIcon } from '@assets/svg/user-solid.svg';
import { ReactComponent as InactiveIcon } from '@assets/svg/user-slash-solid.svg';

import history from '../../../historyData';

const OrganizationTable = ({
  paginationConfig,
  sortConfig,
  handleTableChange,
  organizationData = {},
  organizationLoading,
  onSearchChange,
  handleDeleteModalOpen,
  handleStatusModalOpen,
}) => {
  const sortOrder = sortConfig?.sortBy === 'ASC' ? 'ascend' : 'descend';

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'name' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'email' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'SLUG',
      dataIndex: 'slug',
      key: 'slug',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'LOGO',
      dataIndex: 'logo',
      key: 'logo',
      width: 80,
      ellipsis: true,
      align: 'center',
      className: 'max-width-column',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (value) =>
        value ? <Image src={value} width={30} height={30} /> : '-',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: 150,
      sorter: true,
      sortOrder: sortConfig?.sortOn === 'createdAt' ? sortOrder : null,
      align: 'left',
      className: 'max-width-column',
      render: (value) => dayjs(value)?.format(DATE_FORMATS?.REGULAR),
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value) => (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 200,
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => (
        <div className="action-button">
          <Tooltip title="Status" placement="top" zIndex={4}>
            {value?.isActive ? (
              <InactiveIcon
                onClick={() => handleStatusModalOpen(value)}
                className="mr-16 pointer svg-icon"
              />
            ) : (
              <ActiveIcon
                onClick={() => handleStatusModalOpen(value)}
                className="mr-16 pointer svg-icon"
                width={20}
              />
            )}
          </Tooltip>
          <Tooltip title="Edit" placement="top" zIndex={4}>
            <EditIcon
              onClick={() =>
                history?.push(
                  `${ROUTES?.ORGANIZATIONS}/edit/${value?.id}/${TABS?.ORGANIZATION?.key}`,
                )
              }
              className="mr-16 pointer svg-icon"
            />
          </Tooltip>
          <Tooltip title="Delete" placement="top" zIndex={4}>
            <DeleteIcon
              onClick={() => handleDeleteModalOpen(value)}
              className="mr-16 pointer svg-icon"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          onChange={onSearchChange}
          className="search-box ml-16"
        />
      </div>
      <CommonTable
        columns={columns}
        data={organizationData?.data}
        onChange={handleTableChange}
        paginationConfig={{
          total: organizationData?.count,
          current: paginationConfig?.skip,
          pageSize: paginationConfig?.limit,
        }}
        loading={{ spinning: organizationLoading, size: 'default' }}
        rowKey={(record) => record?.id}
        rowClassName="pointer"
        onRow={(record) => ({
          onClick: () =>
            history?.push(
              `${ROUTES?.ORGANIZATIONS}/view/${record?.id}/${TABS?.ORGANIZATION?.key}`,
            ),
        })}
      />
    </>
  );
};

export default OrganizationTable;
