import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { AppContext } from '@context';
import { getSignedUrl, fileUpload } from '@common/utils';
import CommonHeader from '@components/CommonHeader';
import { ROUTES } from '@common/constants';

import RoofExtensionsForm from '../components/RoofExtensionsForm';
import {
  CREATE_ROOF_EXTENSION,
  UPDATE_ROOF_EXTENSION,
} from '../graphql/Mutations';
import { GET_ROOF_EXTENSIONS, GET_SIGNED_URL } from '../graphql/Queries';
import history from '../../../historyData';

const CreateRoofExtensions = () => {
  const { mode, id } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);
  const [roofExtensionLogo, setRoofExtensionLogo] = useState([]);

  const [createRoofExtension, { loading: createLoading }] = useMutation(
    CREATE_ROOF_EXTENSION,
  );

  const [updateRoofExtension, { loading: updateLoading }] = useMutation(
    UPDATE_ROOF_EXTENSION,
  );

  const [getRoofExtension, { data: roofExtensionData }] = useLazyQuery(
    GET_ROOF_EXTENSIONS,
  );

  const fetchRoofExtension = async () => {
    try {
      await getRoofExtension({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (id) {
      fetchRoofExtension();
    }
  }, []);

  const onEdit = () => {
    setIsView(false);
    history?.push(`${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}/edit/${id}`);
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(`${ROUTES?.MASTER}${ROUTES?.ROOF_EXTENSIONS}`);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const { isActive, tooltipTitle, tooltipBody, ...rest } = values;

    const dataToSend = {
      ...rest,
      isActive: JSON?.parse(isActive),
      tooltip: { title: tooltipTitle, body: tooltipBody },
    };

    try {
      let imgKey;
      if (values?.imageUrl && !roofExtensionLogo?.[0]?.url) {
        await getSignedUrl(
          values?.imageUrl?.[0]?.originFileObj,
          GET_SIGNED_URL,
        )?.then(async (res) => {
          const signedImageUrl =
            res?.getRoofExtensionUploadSignedUrl?.signedUrl;
          imgKey = res?.getRoofExtensionUploadSignedUrl?.key;
          await fileUpload(
            signedImageUrl,
            values?.imageUrl?.[0]?.originFileObj,
          );
          dataToSend.imageUrl = imgKey;
        });
      } else if (roofExtensionLogo?.[0]?.url) {
        delete dataToSend.imageUrl;
      }
      if (id) {
        try {
          await updateRoofExtension({
            variables: {
              where: { id },
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createRoofExtension({
            variables: {
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (roofExtensionData?.roofExtension?.data) {
      const dataToPass = {
        key: roofExtensionData?.roofExtension?.data?.key,
        label: roofExtensionData?.roofExtension?.data?.label,
        description: roofExtensionData?.roofExtension?.data?.description,
        imageUrl: roofExtensionData?.roofExtension?.data?.imageUrl,
        isActive: JSON.stringify(
          roofExtensionData?.roofExtension?.data?.isActive,
        ),
        tooltipTitle: roofExtensionData?.roofExtension?.data?.tooltip?.title,
        tooltipBody: roofExtensionData?.roofExtension?.data?.tooltip?.body,
      };
      setRoofExtensionLogo([
        { url: roofExtensionData?.roofExtension?.data?.imageUrl },
      ]);
      form?.setFieldsValue(dataToPass);
    }
  }, [roofExtensionData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <CommonHeader
        heading={id ? `${mode} Roof Extension` : 'Add Roof Extension'}
        customLink={ROUTES?.MASTER}
      >
        {isView ? (
          <Button className="common-button" size="middle" onClick={onEdit}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              type="primary"
              className="common-button mr-16"
              size="middle"
              onClick={onCancelEdit}
            >
              Cancel
            </Button>
            <Button
              className="common-button"
              size="middle"
              onClick={form?.submit}
              loading={createLoading || updateLoading}
              disabled={createLoading || updateLoading || isDisabled}
            >
              Save
            </Button>
          </>
        )}
      </CommonHeader>
      <Card className="full-height-with-nav" title="Roof Extension">
        <RoofExtensionsForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          isView={isView}
          roofExtensionLogo={roofExtensionLogo}
          setRoofExtensionLogo={setRoofExtensionLogo}
        />
      </Card>
    </>
  );
};

export default CreateRoofExtensions;
