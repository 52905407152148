import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      token
      user {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      email
      firstName
      lastName
      profileImage
      isActive
    }
  }
`;
