import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_ROOF_TYPES = gql`
  query($filter: AdminListRoofTypeFilter, $sort: [ListRoofTypeSort!]) {
    adminRoofTypes(filter: $filter, sort: $sort) {
      count
      data {
        description
        id
        tooltip {
          title
          body
        }
        imageUrl
        isActive
        key
        label
      }
    }
  }
`;

export const GET_ROOF_TYPES = gql`
  query($where: RoofTypeWhereUniqueInput!) {
    roofType(where: $where) {
      data {
        description
        id
        tooltip {
          title
          body
        }
        imageUrl
        isActive
        key
        label
      }
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query($data: GetRoofUploadSignedUrlInput!) {
    getRoofTypeUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
