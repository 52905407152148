import { message, notification } from 'antd';
import { forIn } from 'lodash';
import moment from 'moment';

import {
  ALLOW_IMAGE_TYPES,
  defaultDateFormat,
  MAX_IMAGE_SIZE,
  REGEX,
} from '@common/constants';
import { GET_SIGNED_URL } from '@components/graphql/Mutation';

import client from '../apollo';
import api from './api';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && moment && format) {
    return moment(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
    whitespace: true,
  },
  whitespaceNotAllowed: {
    required: false,
    message: 'Should be include characters!',
    whitespace: true,
  },
  email: () => ({
    validator(rule, value) {
      if (!value?.trim()) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Name!');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number!');
      }
      return Promise?.resolve();
    },
  }),
  phone: () => ({
    validator(rule, value) {
      if (!value?.trim()) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.PHONE?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Contact Number!');
      }
      return Promise?.resolve();
    },
  }),
  positiveNumber: (isZeroAllowed = true) => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }

      const valueString = value.toString();
      const isValueValid = REGEX?.POSITIVE_NUMBERS?.test(valueString);

      if (valueString.length > 20) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Maximum 20 digits allowed!');
      }

      if (isZeroAllowed) {
        if (!isValueValid) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Should be a valid Number!');
        }
      } else if (!isValueValid || parseInt(value, 10) === 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number!');
      }

      return Promise.resolve();
    },
  }),
  price: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.PRICE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Price!');
      }
      const numericValue = parseFloat(
        value?.toString()?.replace(REGEX?.NOT_DIGIT_CHARACTER, ''),
      );
      const maxValue = 10000000;
      if (numericValue > maxValue) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should not exceed 10000000!');
      }
      return Promise?.resolve();
    },
  }),
  password: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }

      if (!REGEX?.PASSWORD?.test(value) && value?.length < 8) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          `Please enter a password with a minimum of 8 characters!`,
        );
      }

      if (!REGEX?.PASSWORD?.test(value) && value?.length > 16) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          `Please ensure that your password is no longer than 16 characters!`,
        );
      }

      if (!REGEX?.PASSWORD?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(`Password is weak!`);
      }
      return Promise?.resolve();
    },
  }),
  url: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.WEB_URL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(`Should be a valid URL!`);
      }
      return Promise?.resolve();
    },
  }),
  minConfigRule: (minConfig, maxConfig) => ({
    validator(rule, value) {
      if (!value || !maxConfig) {
        return Promise?.resolve();
      }
      const minConfigInt = parseInt(minConfig, 10);
      const maxConfigInt = parseInt(maxConfig, 10);
      if (maxConfigInt < minConfigInt) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Not exceed than max');
      }
      return Promise?.resolve();
    },
  }),
  maxConfigRule: (minConfig, maxConfig) => ({
    validator(rule, value) {
      if (!value || !minConfig) {
        return Promise?.resolve();
      }
      const minConfigInt = parseInt(minConfig, 10);
      const maxConfigInt = parseInt(maxConfig, 10);
      if (minConfigInt > maxConfigInt) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Not lower than min');
      }
      return Promise?.resolve();
    },
  }),
  defaultValConfigRule: (minConfig, maxConfig) => ({
    validator(rule, value) {
      if (!value || !minConfig || !maxConfig) {
        return Promise?.resolve();
      }
      const minConfigInt = parseInt(minConfig, 10);
      const maxConfigInt = parseInt(maxConfig, 10);
      if (value < minConfigInt || value > maxConfigInt) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Not valid default value');
      }
      return Promise?.resolve();
    },
  }),
  stepConfigRule: (minConfig, maxConfig) => ({
    validator(rule, value) {
      if (!value || !minConfig || !maxConfig) {
        return Promise?.resolve();
      }
      const maxConfigInt = parseInt(maxConfig, 10);
      if (value > maxConfigInt) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Not valid step value');
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export const openNotification = (type, msg) => {
  notification[type]({
    message: msg,
    placement: 'topRight',
    duration: 5,
  });
};

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.addEventListener('error', (error) => {
        if (error) {
          reject(error);
        }
      });
      xhr?.addEventListener('load', function () {
        if (this.readyState === 4) {
          if (xhr?.status === 200) {
            resolve(xhr?.response);
          } else {
            reject(xhr?.status);
          }
        }
      });

      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    } catch (error) {
      reject(error);
      message?.error(error?.message);
    }
  });
}

export const getSignedUrl = async (fileObj, query) => {
  const fileName = fileObj?.name;

  const response = await client?.mutate({
    mutation: query,
    variables: {
      action: 'write',
      data: {
        fileName,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type,
    },
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message?.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message?.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const findPermission = (permissionObj) => {
  let permission;
  forIn(permissionObj, (value, key) => {
    if (permissionObj?.[key] === true) {
      permission = key;
    }
  });
  return permission;
};

export const verifyPermission = (permissions, routeKey, allowedPermission) => {
  const modulePermission = permissions?.find(
    (item) => item?.routeKey === routeKey,
  );
  const permission = findPermission(modulePermission);
  const hasPermission = allowedPermission?.includes(permission);
  return hasPermission;
};

export const verifyTabsPermission = (
  permissions,
  routeKey,
  tabKey,
  allowedPermission,
) => {
  const modulePermission = permissions?.find(
    (item) => item?.routeKey === routeKey,
  );
  const permission = findPermission(
    modulePermission?.tabKeys?.find((item) => item?.key === tabKey),
  );
  const hasPermission = allowedPermission?.includes(permission);
  return hasPermission;
};

export const getImageDimensions = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();

    reader.onload = (e) => {
      // eslint-disable-next-line no-undef
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img?.width,
          height: img?.height,
        });
      };

      img.onerror = (error) => {
        reject(error);
      };
      img.src = e?.target?.result;
    };

    reader.readAsDataURL(file);
  });

export const validateImage = async (
  info,
  form,
  setImages,
  images,
  onlyFormatValidate = false,
) => {
  if (!info?.file?.url && !info?.file?.originFileObj) {
    const {
      file: { name: fileName = '', key = '', size },
    } = info;
    const ext = fileName?.substring(fileName?.lastIndexOf('.') + 1);
    const extKey = key?.substring(key?.lastIndexOf('.') + 1);

    if (!ALLOW_IMAGE_TYPES?.includes(ext || extKey)) {
      setImages(info?.fileList?.slice(0, -1));
      form?.setFieldsValue({ [images]: info?.fileList?.slice(0, -1) });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
      return false;
    }

    if (!onlyFormatValidate) {
      const dimension = await getImageDimensions(info?.file);
      const { width, height } = dimension || {};
      const aspectRatio = width / height;

      if (width <= 200 || height <= 200) {
        setImages(info.fileList.slice(0, -1));
        form?.setFieldsValue({ [images]: info?.fileList?.slice(0, -1) });
        message?.destroy();
        message?.error(
          'The image is too small, the minimum dimensions are 200x200 pixels',
        );
        return false;
      }

      if (width >= 2000 || height >= 2000) {
        setImages(info.fileList.slice(0, -1));
        form?.setFieldsValue({ [images]: info?.fileList?.slice(0, -1) });
        message?.destroy();
        message?.error(
          'The image is too large, the maximum dimensions are 2000x2000 pixels',
        );
        return false;
      }

      if (aspectRatio < 1 || aspectRatio > 5) {
        setImages(info.fileList.slice(0, -1));
        form?.setFieldsValue({ [images]: info?.fileList?.slice(0, -1) });
        message?.destroy();
        message?.error(
          'Please upload an image with an aspect ratio between 1:1 and 5:1',
        );
        return false;
      }

      if (size > MAX_IMAGE_SIZE) {
        setImages(info.fileList.slice(0, -1));
        form?.setFieldsValue({ [images]: info?.fileList?.slice(0, -1) });
        message?.destroy();
        message?.error('Allowed maximum size is 15Mb');
        return false;
      }
    }
  }
  form?.setFieldsValue({ [images]: info?.fileList });
  setImages(info?.fileList);
  return true;
};

export const billableAmount = (price, memberShipFees, refundableDeposit) => {
  const totalAmount =
    parseInt(price || 0, 10) +
    (memberShipFees !== '-' ? parseInt(memberShipFees || 0, 10) : 0) +
    parseInt(refundableDeposit || 0, 10);
  return totalAmount;
};

export const hasNonEmptyPermission = (permissionKeys, permissions) => {
  if (typeof permissionKeys === 'string') {
    return (
      permissions?.[permissionKeys] !== undefined &&
      permissions?.[permissionKeys]?.length !== 0
    );
  }
  if (Array?.isArray(permissionKeys)) {
    return permissionKeys?.some(
      (permission) =>
        permissions?.[permission] !== undefined &&
        permissions?.[permission]?.length !== 0,
    );
  }
  return false;
};

export const hasMatchingTabKey = (tabKeys, permissions) =>
  tabKeys?.some((tab) => hasNonEmptyPermission(tab?.tabKey, permissions));

export const getRedirectItem = (permissions, array = [], isAllAccess = false) =>
  array
    ?.map((item) => {
      const matchingKey = item?.tabKeys?.find(
        (tab) =>
          Object?.prototype?.hasOwnProperty?.call(permissions, tab?.tabKey) &&
          permissions?.[tab?.tabKey]?.length > 0,
      );
      const matchingPermissionKey = hasNonEmptyPermission(
        [item?.permissionKey],
        permissions,
      );

      return matchingKey || matchingPermissionKey || isAllAccess
        ? item?.route
        : null;
    })
    ?.filter(Boolean);

export const getRedirectSubmenuItem = (permissions, array = []) =>
  array
    ?.map((item) =>
      hasNonEmptyPermission(item?.tabKey, permissions)
        ? item?.route
        : undefined,
    )
    ?.filter((item) => item);

export const filterTabsByPermissions = (tabList, permissions) => {
  const filteredTabs = tabList?.filter(
    (tab) =>
      Object?.prototype?.hasOwnProperty?.call(
        permissions,
        tab?.permissionKey,
      ) && permissions?.[tab?.permissionKey]?.length > 0,
  );
  return filteredTabs;
};

export const hasPermission = (permissionKey, permissionType, permissions) => {
  if (Array?.isArray(permissionType)) {
    return permissionType?.some((type) =>
      permissions?.[permissionKey]?.includes(type),
    );
  }
  return permissions?.[permissionKey]?.includes(permissionType) ?? false;
};

export const handleDownload = (fileUrl) => {
  if (fileUrl) {
    fetch(fileUrl)
      ?.then((response) => response?.blob())
      ?.then((blob) => {
        // eslint-disable-next-line no-undef
        const url = window?.URL?.createObjectURL(blob);
        // eslint-disable-next-line no-undef
        const link = document?.createElement('a');
        link.href = url;
        link.download = '';
        // eslint-disable-next-line no-undef
        document?.body?.appendChild(link);
        link?.click();
        // eslint-disable-next-line no-undef
        document?.body?.removeChild(link);
      })
      ?.catch((error) => error);
  }
};

export const handleCopy = (event) => {
  // eslint-disable-next-line no-undef
  window?.navigator?.clipboard?.writeText(event);
  message?.destroy();
  message?.success('Text copied to clipboard');
};

export const rgbToHsl = (r, g, b) => {
  const ra = r / 255;
  const ga = g / 255;
  const ba = b / 255;
  const l = Math.max(ra, ga, ba);
  const s = l - Math.min(ra, ga, ba);

  const condition1 = l === ga ? 2 + (ba - ra) / s : 4 + (ra - ga) / s;
  const condition2 = l === ra ? (ga - ba) / s : condition1;
  const h = s ? condition2 : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    // eslint-disable-next-line no-nested-ternary
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

export const hslToRgb = (h, s, l) => {
  const sa = s / 100;
  const la = l / 100;
  const k = (n) => (n + h / 30) % 12;
  const a = sa * Math.min(la, 1 - la);
  const f = (n) =>
    la - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [
    Math?.round(255 * f(0)),
    Math?.round(255 * f(8)),
    Math?.round(255 * f(4)),
  ];
};

export const colorAdjusting = (color, colorTone) => {
  // Remove the '#' from the hex color
  const selectedColor = color.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(selectedColor.slice(0, 2), 16);
  const g = parseInt(selectedColor.slice(2, 4), 16);
  const b = parseInt(selectedColor.slice(4, 6), 16);

  // Convert the rgb color to hsl
  const hslColor = rgbToHsl(r, g, b);

  // Convert the hsl color to rgb after white tone change
  const newRgb = hslToRgb(hslColor[0], hslColor[1], colorTone);

  const adjustedColor = `#${newRgb[0]
    .toString(16)
    .padStart(2, '0')}${newRgb[1]
    .toString(16)
    .padStart(2, '0')}${newRgb[2].toString(16).padStart(2, '0')}`;

  return adjustedColor;
};

export const convertColor = (color, format) => {
  let formattedColor;
  switch (format) {
    case 'rgb':
      formattedColor = color?.toRgbString();
      break;
    case 'hsb':
      formattedColor = color?.toHsbString();
      break;
    case 'hex':
      formattedColor = color?.toHexString();
      break;
    default:
      formattedColor = color.toHexString();
  }
  return formattedColor;
};
