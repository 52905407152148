import { useLazyQuery } from '@apollo/client';
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { DATE_FORMATS } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import { HOUSE_DESCRIPTION_DATA } from '../graphql/Queries';
import CompanySelect from './CompanySelect';

const { RangePicker } = DatePicker;

const HouseDescTable = () => {
  const [houseDescData, setHouseDescData] = useState([]);
  const [isHouseDescDataLoading, setIsHouseDescDataLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({});

  const [houseDescriptionData] = useLazyQuery(HOUSE_DESCRIPTION_DATA, {
    onCompleted: (response) => {
      setHouseDescData(response?.houseDescriptionData);
      setIsHouseDescDataLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsHouseDescDataLoading(true);
    houseDescriptionData();
  }, []);

  useEffect(() => {
    setIsHouseDescDataLoading(true);
    const filters = {
      dateRange:
        selectedDateRange?.from && selectedDateRange?.to
          ? {
              from: selectedDateRange?.from,
              to: selectedDateRange?.to,
            }
          : undefined,
      organizationId: selectedCompany || undefined,
    };
    houseDescriptionData({
      variables: {
        filters,
      },
    });
  }, [selectedCompany, selectedDateRange]);

  const columns = [
    {
      title: 'TENURE TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.description || '-',
    },
    {
      title: 'TOTAL SELECTED',
      dataIndex: 'totalSelected',
      key: 'totalSelected',
      ellipsis: true,
      width: 220,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.data?.totalCount || 0,
    },
    {
      title: (
        <div>
          <div>TOTAL SELECTED</div>
          <div>(WITH-EPC)</div>
        </div>
      ),
      dataIndex: 'totalSelectedWithEpc',
      key: 'totalSelectedWithEpc',
      ellipsis: true,
      width: 220,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.data?.withEpcCount || 0,
    },
    {
      title: (
        <div>
          <div>TOTAL SELECTED</div>
          <div>(WITHOUT-EPC)</div>
        </div>
      ),
      dataIndex: 'totalSelectedWithoutEpc',
      key: 'totalSelectedWithoutEpc',
      ellipsis: true,
      width: 220,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.data?.withoutEpcCount || 0,
    },
    {
      title: (
        <div>
          <div>TOTAL RECOMMENDATIONS</div>
          <div>(GENERATED)</div>
        </div>
      ),
      dataIndex: 'totalRecommendations',
      key: 'totalRecommendations',
      ellipsis: true,
      width: 220,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.data?.recommendations?.totalCount || 0,
    },
    {
      title: (
        <div>
          <div>TOTAL RECOMMENDATIONS</div>
          <div>(WITH-EPC)</div>
        </div>
      ),
      dataIndex: 'totalRecommendationsWithEPC',
      key: 'totalRecommendationsWithEPC',
      ellipsis: true,
      width: 220,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.data?.recommendations?.withEpcCount || 0,
    },
    {
      title: (
        <div>
          <div>TOTAL RECOMMENDATIONS</div>
          <div>(WITHOUT-EPC)</div>
        </div>
      ),
      dataIndex: 'totalRecommendationsWithoutEPC',
      key: 'totalRecommendationsWithoutEPC',
      ellipsis: true,
      width: 220,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.data?.recommendations?.withoutEpcCount || 0,
    },
  ];

  const onRangePickerChange = (values) => {
    const rangeObj = {
      from: values?.[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };
    setSelectedDateRange(values ? rangeObj : {});
  };

  return (
    <>
      <div className="d-flex justify-end mb-24">
        <CompanySelect
          className="filter-select mr-16"
          onChange={(value) => setSelectedCompany(value)}
        />
        <RangePicker
          popupClassName="picker-with-scroll"
          format={DATE_FORMATS?.REGULAR}
          onChange={onRangePickerChange}
          disabledDate={(current) =>
            current && current?.startOf('day') > moment()?.startOf('day')
          }
          placeholder={['From Date', 'To Date']}
        />
      </div>
      <CommonTable
        columns={columns}
        data={houseDescData || []}
        loading={isHouseDescDataLoading}
        rowKey={() =>
          Math?.random()?.toString(36)?.substring(2) +
          new Date()?.getTime()?.toString(36)
        }
        className="houseDesc-table"
      />
    </>
  );
};

export default HouseDescTable;
