import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_HOUSE_TYPE = gql`
  mutation($data: CreateHouseTypeInput!) {
    createHouseType(data: $data) {
      message
    }
  }
`;

export const UPDATE_HOUSE_TYPE = gql`
  mutation($data: UpdateHouseTypeInput!, $where: HouseTypeWhereUniqueInput!) {
    updateHouseType(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_HOUSE_TYPE = gql`
  mutation($where: HouseTypeWhereUniqueInput!) {
    deleteHouseType(where: $where) {
      message
    }
  }
`;
