import { Col, Form, Input, Radio, Row } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';

import { formValidatorRules } from '@common/utils';
import 'react-quill/dist/quill.snow.css';

const HouseShapesForm = ({ form, onFinish, handleShowPrompt, isView }) => {
  const { Item } = Form;
  const { required } = formValidatorRules;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        className={`${isView ? 'common-view-form' : 'form'} master-form`}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Key"
              name="key"
              rules={[{ ...required, message: 'Please Enter Key!' }]}
            >
              <Input placeholder="Enter Key" maxLength={50} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Label"
              name="label"
              rules={[{ ...required, message: 'Please Enter Label!' }]}
            >
              <Input placeholder="Enter Label" maxLength={255} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Description" name="description">
              <Input.TextArea
                rows={4}
                placeholder="Enter Description"
                maxLength={255}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Is Active"
              name="isActive"
              initialValue="false"
              rules={[{ ...required, message: 'Please Enter Is Active!' }]}
            >
              <Radio.Group>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Radio.Group>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Tooltip Title" name="tooltipTitle">
              <Input placeholder="Enter Label" maxLength={255} />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Tooltip Body" name="tooltipBody">
              <ReactQuill
                readOnly={!!isView}
                theme="snow"
                placeholder="Enter Tooltip Body"
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default HouseShapesForm;
