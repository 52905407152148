import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_HEATING_SYSTEMS = gql`
  query(
    $filter: AdminListHeatingSystemFilter
    $sort: [ListHeatingSystemSort!]
  ) {
    adminHeatingSystems(filter: $filter, sort: $sort) {
      count
      data {
        id
        key
        tooltip {
          title
          body
        }
        label
        isActive
        description
        createdAt
      }
    }
  }
`;

export const GET_HEATING_SYSTEM = gql`
  query($where: HeatingSystemWhereUniqueInput!) {
    heatingSystem(where: $where) {
      data {
        id
        key
        tooltip {
          title
          body
        }
        label
        order
        isActive
        description
      }
    }
  }
`;
