import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_HOUSE_SHAPES = gql`
  query($filter: AdminListHouseShapeFilter, $sort: [ListHouseShapeSort!]) {
    adminHouseShapes(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        description
        id
        tooltip {
          title
          body
        }
        key
        label
        isActive
      }
    }
  }
`;

export const GET_HOUSE_SHAPE = gql`
  query($where: HouseShapeWhereUniqueInput!) {
    houseShape(where: $where) {
      data {
        id
        key
        tooltip {
          title
          body
        }
        label
        order
        isActive
        description
      }
    }
  }
`;
