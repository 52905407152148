import { Col, Form, Input, Radio, Row, Typography, Upload } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';

import CommonPreview from '@components/CommonPreview';
import { formValidatorRules, validateImage, getBase64 } from '@common/utils';
import 'react-quill/dist/quill.snow.css';

const MaterialCategoriesForm = ({
  form,
  onFinish,
  handleShowPrompt,
  isView,
  materialCategoryLogo = [],
  setMaterialCategoryLogo,
}) => {
  const { Item } = Form;
  const { required } = formValidatorRules;

  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const handleChange = (info) => {
    validateImage(info, form, setMaterialCategoryLogo, 'imageUrl', true);
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={previewImage}
        setImage={setPreviewImage}
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        className={`${isView ? 'common-view-form' : 'form'} master-form`}
      >
        <Typography.Title level={5}>Material Category</Typography.Title>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Key"
              name="key"
              rules={[{ ...required, message: 'Please Enter Key!' }]}
            >
              <Input placeholder="Enter Key" maxLength={50} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Label"
              name="label"
              rules={[{ ...required, message: 'Please Enter Label!' }]}
            >
              <Input placeholder="Enter Label" maxLength={255} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Is Active"
              name="isActive"
              initialValue="false"
              rules={[{ ...required, message: 'Please Enter Is Active!' }]}
            >
              <Radio.Group>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Radio.Group>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="REQUIRED"
              name="required"
              initialValue="false"
              rules={[{ ...required, message: 'Please Enter Required!' }]}
            >
              <Radio.Group>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Radio.Group>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              name="imageUrl"
              label="Image"
              rules={[{ required: true, message: 'Please Upload image!' }]}
            >
              <Upload
                name="avatar"
                listType="picture-circle"
                maxCount={1}
                fileList={materialCategoryLogo}
                beforeUpload={() => false}
                onPreview={handlePreview}
                onChange={handleChange}
                className="avatar-uploader"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                }}
              >
                {materialCategoryLogo?.length === 0 && <PlusOutlined />}
              </Upload>
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Description" name="description">
              <Input.TextArea
                rows={4}
                placeholder="Enter Description"
                maxLength={1000}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Tooltip Title" name="tooltipTitle">
              <Input placeholder="Enter Label" maxLength={255} />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Tooltip Body" name="tooltipBody">
              <ReactQuill
                readOnly={!!isView}
                theme="snow"
                placeholder="Enter Tooltip Body"
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MaterialCategoriesForm;
