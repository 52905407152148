import React from 'react';

import DashboardTabs from '../components/DashboardTabs';
import WithEpcChart from '../components/WithEpcChart';
import WithoutEpcChart from '../components/WithoutEpcChart';

const Visualization = () => (
  <DashboardTabs>
    <div className="site-statistic-demo-card">
      <WithoutEpcChart />
    </div>
    <div className="site-statistic-demo-card">
      <WithEpcChart />
    </div>
  </DashboardTabs>
);

export default Visualization;
