import {
  Col,
  ColorPicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import _, { map } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  ACCENT_COLORS,
  CONFIG_TYPE,
  EMAIL_OPTIONS,
  EMPTY_STATE_IMAGES_KEYS,
  FONT_FAMILY,
  NUMBER_FIELD_TYPES,
  PRIMARY_COLORS,
  SORT_OPTIONS,
} from '@common/constants';
import { colorAdjusting, convertColor } from '@common/utils';

import NumberComponent from '../../../components/NumberComponent';

const OrganizationConfigForm = ({
  form,
  onFinish,
  handleShowPrompt,
  isView,
  organizationConfigData,
  themeConfig,
  setThemeConfig,
  buttonsConfig,
  setButtonsConfig,
  layoutConfig,
  setLayoutConfig,
  bookSurveyConfig,
  setBookSurveyConfig,
  waitListConfig,
  setWaitListConfig,
  updateEpcConfig,
  setUpdateEpcConfig,
  selectedFont,
  setSelectedFont,
  selectedEmailPlace,
  setSelectedEmailPlace,
  trackingId,
  setTrackingId,
  emptyImagesState,
  setEmptyImagesState,
  selectedDefaultSort,
  setSelectedDefaultSort,
}) => {
  const [colorFormat, setColorFormat] = useState('hex');

  const handleColorPick = (value, index, colorIndex) => {
    const latestThemeConfig = themeConfig;
    if (index === 0 && colorIndex === 0) {
      latestThemeConfig[index].colors[1].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryTint1,
      );
      latestThemeConfig[index].colors[2].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryTint2,
      );
      latestThemeConfig[index].colors[3].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryShade1,
      );
      latestThemeConfig[index].colors[4].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryShade2,
      );
    }

    if (index === 1 && colorIndex === 0) {
      latestThemeConfig[index].colors[1].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentTint1,
      );
      latestThemeConfig[index].colors[2].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentTint2,
      );
      latestThemeConfig[index].colors[3].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentShade1,
      );
      latestThemeConfig[index].colors[4].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentShade2,
      );
    }
    latestThemeConfig[index].colors[colorIndex].value = value?.toHexString();
    setThemeConfig([...latestThemeConfig]);
  };

  const handleBtnConfig = (value, index, configIndex, type, isOtherColor) => {
    const latestBtnConfig = buttonsConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      if (isOtherColor) {
        latestBtnConfig[index].configs[configIndex].value = convertColor(
          value,
          colorFormat,
        );
      } else {
        latestBtnConfig[index].configs[
          configIndex
        ].value = value?.toHexString();
      }
    } else {
      latestBtnConfig[index].configs[configIndex].value = value;
    }
    setButtonsConfig([...latestBtnConfig]);
  };

  const handleLayoutConfig = (
    value,
    index,
    configIndex,
    type,
    isOtherColor,
  ) => {
    const latestLayoutConfig = layoutConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      if (isOtherColor) {
        latestLayoutConfig[index].configs[configIndex].value = convertColor(
          value,
          colorFormat,
        );
      } else {
        latestLayoutConfig[index].configs[
          configIndex
        ].value = value?.toHexString();
      }
    } else {
      latestLayoutConfig[index].configs[configIndex].value = value;
    }
    setLayoutConfig([...latestLayoutConfig]);
  };

  const handleBookSurveyConfig = (value, index, configIndex, type) => {
    const latestBookSurveyConfig = bookSurveyConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      latestBookSurveyConfig[index].configs[
        configIndex
      ].value = value?.toHexString();
    } else {
      latestBookSurveyConfig[index].configs[configIndex].value = value;
    }
    setBookSurveyConfig([...latestBookSurveyConfig]);
  };

  const handleWaitListConfig = (value, index, configIndex, type) => {
    const latestBtnConfig = waitListConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      latestBtnConfig[index].configs[configIndex].value = value?.toHexString();
    } else {
      latestBtnConfig[index].configs[configIndex].value = value;
    }
    setWaitListConfig([...latestBtnConfig]);
  };

  const handleUpdateEpcConfig = (value, index, configIndex) => {
    const latestBtnConfig = updateEpcConfig;
    latestBtnConfig[index].configs[configIndex].value = value;
    setUpdateEpcConfig([...latestBtnConfig]);
  };

  useEffect(() => {
    if (organizationConfigData) {
      const {
        fonts,
        formSteps,
        themeColors,
        waitList,
        buttons,
        layout,
        analytics,
        updateEpcCta,
        bookSurvey,
        recommendationsDefaultSort,
      } = organizationConfigData;
      if (fonts) {
        setSelectedFont(fonts?.fontFamily);
      }
      if (formSteps?.length > 0) {
        setSelectedEmailPlace(formSteps?.[0]?.order);
      }
      if (themeColors) {
        _.forEach(themeConfig, (category) => {
          _.forEach(category.colors, (color) => {
            const matchingValue = themeColors[color.name];
            _.set(color, 'value', matchingValue || color?.value);
          });
        });
        setThemeConfig(themeConfig);
      }
      if (buttons) {
        _.forEach(buttonsConfig, (category) => {
          _.forEach(category.configs, (config) => {
            const matchingValue = buttons[config.name];
            _.set(config, 'value', matchingValue || config?.value);
          });
        });
        setButtonsConfig(buttonsConfig);
      }
      if (layout) {
        _.forEach(layoutConfig, (category) => {
          _.forEach(category.configs, (config) => {
            const matchingValue = layout[config.name];
            _.set(config, 'value', matchingValue || config?.value);
          });
        });
        setLayoutConfig(layoutConfig);
      }
      if (bookSurvey) {
        _.forEach(bookSurveyConfig, (category) => {
          _.forEach(category.configs, (config) => {
            const matchingValue = bookSurvey[config.name];
            _.set(config, 'value', matchingValue || config?.value);
          });
        });
        setBookSurveyConfig(bookSurveyConfig);
      }
      if (waitList) {
        _.forEach(waitListConfig, (category) => {
          _.forEach(category.configs, (config) => {
            const matchingValue = waitList[config.name];
            _.set(config, 'value', matchingValue || config?.value);
          });
        });
        setWaitListConfig(waitListConfig);
      }
      if (updateEpcCta) {
        _.forEach(updateEpcConfig, (category) => {
          _.forEach(category.configs, (config) => {
            const matchingValue = updateEpcCta[config.name];
            _.set(config, 'value', matchingValue || config?.value);
          });
        });
        setUpdateEpcConfig(updateEpcConfig);
      }
      if (analytics?.key) {
        setTrackingId(analytics?.key);
      }
      setSelectedDefaultSort(recommendationsDefaultSort);
    }
  }, [organizationConfigData]);

  const handleVisibilityChange = (key, value) => {
    setEmptyImagesState((prevImages) =>
      prevImages?.map((image) =>
        image?.key === key ? { ...image, visibility: value } : image,
      ),
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={handleShowPrompt}
      disabled={!!isView}
      className={`${isView ? 'common-view-form' : 'form'} organization-form`}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Typography.Title level={5}>Font Config</Typography.Title>
          <Row className="config-form-row" align="middle">
            <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
              <Typography.Text>Font-Family : </Typography.Text>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Select
                value={selectedFont}
                onChange={(value) => {
                  setSelectedFont(value);
                }}
                options={FONT_FAMILY}
                placeholder="Select Font-Family"
                disabled={!!isView}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Typography.Title level={5}>Email Config</Typography.Title>
          <Row className="config-form-row" align="middle">
            <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
              <Typography.Text>Capture Email At :</Typography.Text>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Select
                value={selectedEmailPlace || 0}
                onChange={(value) => {
                  setSelectedEmailPlace(value);
                }}
                options={EMAIL_OPTIONS}
                placeholder="Select Email Option"
                disabled={!!isView}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Typography.Title level={5}>Sorting Config</Typography.Title>
          <Row className="config-form-row" align="middle">
            <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
              <Typography.Text>Default sort option : </Typography.Text>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Select
                value={selectedDefaultSort}
                onChange={(value) => {
                  setSelectedDefaultSort(value);
                }}
                options={map(SORT_OPTIONS, (option) => option)}
                placeholder="Select Default Sort Option"
                disabled={!!isView}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Typography.Title level={5}>Theme Config</Typography.Title>
      {themeConfig &&
        themeConfig?.map((item, index) => (
          <Row
            align="middle"
            key={`${item?.title}`}
            className="config-form-row"
          >
            <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
              {item?.title} :
            </Col>
            <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
              <Row gutter={[20, 12]} align="middle">
                {item?.colors?.map((color, colorIndex) => (
                  <Col
                    key={`${color?.name}`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={6}
                    xxl={6}
                  >
                    <ColorPicker
                      format="hex"
                      showText={() => color?.title}
                      disabled={!!isView}
                      value={color?.value}
                      onChangeComplete={(value) => {
                        handleColorPick(value, index, colorIndex);
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
      <Typography.Title level={5}>Button Config</Typography.Title>
      {buttonsConfig &&
        buttonsConfig?.map((item, index) => (
          <Row
            align="middle"
            key={`${item?.title}`}
            className="config-form-row"
          >
            <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
              {item?.title} :
            </Col>
            <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
              <Row gutter={[20, 12]} align="middle">
                {item?.configs?.map((config, configIndex) => {
                  if (config?.type === CONFIG_TYPE?.COLOR) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <ColorPicker
                          format={config?.isOtherColor ? colorFormat : 'hex'}
                          defaultFormat="hex"
                          showText={() => config?.title}
                          disabled={!!isView}
                          value={config?.value}
                          onChangeComplete={(value) =>
                            handleBtnConfig(
                              value,
                              index,
                              configIndex,
                              config?.type,
                              config?.isOtherColor,
                            )
                          }
                          onFormatChange={(format) => {
                            setColorFormat(format);
                          }}
                          onOpenChange={() => setColorFormat('hex')}
                        />
                      </Col>
                    );
                  }
                  return (
                    <Col
                      key={`${config?.name}`}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={6}
                      xxl={6}
                    >
                      <span>{config?.title}:</span>
                      <NumberComponent
                        isFractional={false}
                        disabled={!!isView}
                        value={config?.value}
                        type={NUMBER_FIELD_TYPES?.BORDER_RADIUS}
                        onChange={(e) =>
                          handleBtnConfig(
                            e?.target?.value,
                            index,
                            configIndex,
                            config?.type,
                          )
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        ))}
      <Typography.Title level={5}>Layout Config</Typography.Title>
      {layoutConfig &&
        layoutConfig?.map((item, index) => (
          <Row
            align="middle"
            key={`${item?.title}`}
            className="config-form-row"
          >
            <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
              {item?.title} :
            </Col>
            <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
              <Row gutter={[20, 12]} align="middle">
                {item?.configs?.map((config, configIndex) => {
                  if (config?.type === CONFIG_TYPE?.COLOR) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <ColorPicker
                          format={config?.isOtherColor ? colorFormat : 'hex'}
                          defaultFormat="hex"
                          showText={() => config?.title}
                          disabled={!!isView}
                          value={config?.value}
                          onChangeComplete={(value) =>
                            handleLayoutConfig(
                              value,
                              index,
                              configIndex,
                              config?.type,
                              config?.isOtherColor,
                            )
                          }
                          onFormatChange={(format) => {
                            setColorFormat(format);
                          }}
                          onOpenChange={() => setColorFormat('hex')}
                        />
                      </Col>
                    );
                  }
                  if (config?.type === CONFIG_TYPE?.SWITCH) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <Row className="config-form-row d-flex align-center">
                          <Typography.Text>{config?.title}</Typography.Text>
                          <Switch
                            checked={config?.value}
                            onChange={(value) =>
                              handleLayoutConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                              )
                            }
                            className="ml-16"
                          />
                        </Row>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      key={`${config?.name}`}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={6}
                      xxl={6}
                    >
                      <span>{config?.title}:</span>
                      <NumberComponent
                        isFractional={false}
                        disabled={!!isView}
                        value={config?.value}
                        type={NUMBER_FIELD_TYPES?.HEIGHT_WIDTH}
                        onChange={(e) =>
                          handleLayoutConfig(
                            e?.target?.value,
                            index,
                            configIndex,
                            config?.type,
                          )
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        ))}
      <Typography.Title level={5}>
        Send Email & Book Survey Config
      </Typography.Title>
      {bookSurveyConfig &&
        bookSurveyConfig?.map((item, index) => (
          <Row
            align="middle"
            key={`${item?.title}`}
            className="config-form-row"
          >
            <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
              {item?.title} :
            </Col>
            <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
              <Row gutter={[20, 12]} align="middle">
                {item?.configs?.map((config, configIndex) => {
                  if (config?.type === CONFIG_TYPE?.COLOR) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <ColorPicker
                          format={config?.isOtherColor ? colorFormat : 'hex'}
                          defaultFormat="hex"
                          showText={() => config?.title}
                          disabled={!!isView}
                          value={config?.value}
                          onChangeComplete={(value) =>
                            handleBookSurveyConfig(
                              value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                          onFormatChange={(format) => {
                            setColorFormat(format);
                          }}
                          onOpenChange={() => setColorFormat('hex')}
                        />
                      </Col>
                    );
                  }
                  if (config?.type === CONFIG_TYPE?.SWITCH) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <Row className="config-form-row d-flex align-center">
                          <Typography.Text>{config?.title}</Typography.Text>
                          <Switch
                            checked={config?.value}
                            onChange={(value) =>
                              handleBookSurveyConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                              )
                            }
                            className="ml-16"
                          />
                        </Row>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      key={`${config?.name}`}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={6}
                      xxl={6}
                    >
                      <span>{config?.title}:</span>
                      <NumberComponent
                        isFractional={false}
                        disabled={!!isView}
                        value={config?.value}
                        type={NUMBER_FIELD_TYPES?.HEIGHT_WIDTH}
                        onChange={(e) =>
                          handleBookSurveyConfig(
                            e?.target?.value,
                            index,
                            configIndex,
                            config?.type,
                          )
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        ))}
      <Typography.Title level={5}>Join Wait List Config</Typography.Title>
      {waitListConfig &&
        waitListConfig?.map((item, index) => (
          <Row
            align="middle"
            key={`${item?.title}`}
            className="config-form-row"
          >
            <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
              {item?.title} :
            </Col>
            <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
              <Row gutter={[20, 12]} align="middle">
                {item?.configs?.map((config, configIndex) => {
                  if (config?.type === CONFIG_TYPE?.COLOR) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <ColorPicker
                          format="hex"
                          showText={() => config?.title}
                          disabled={!!isView}
                          value={config?.value}
                          onChangeComplete={(value) =>
                            handleWaitListConfig(
                              value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                        />
                      </Col>
                    );
                  }
                  if (config?.type === CONFIG_TYPE?.INPUT_TEXT) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <span>{config?.title}:</span>
                        <Input
                          disabled={!!isView}
                          value={config?.value}
                          placeHolder={config?.placeHolder}
                          onChange={(e) =>
                            handleWaitListConfig(
                              e?.target?.value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                        />
                      </Col>
                    );
                  }
                  if (config?.type === CONFIG_TYPE?.SELECT) {
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <div>{config?.title}:</div>
                        <Select
                          disabled={!!isView}
                          value={config?.value}
                          onChange={(value) =>
                            handleWaitListConfig(
                              value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                          options={config?.options}
                          placeholder={config?.placeHolder}
                        />
                      </Col>
                    );
                  }
                  return (
                    <Col
                      key={`${config?.name}`}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={6}
                      xxl={6}
                    >
                      <span>{config?.title}:</span>
                      <NumberComponent
                        isFractional={false}
                        disabled={!!isView}
                        value={config?.value}
                        placeHolder={config?.placeHolder}
                        type={NUMBER_FIELD_TYPES?.BORDER_RADIUS}
                        onChange={(e) =>
                          handleWaitListConfig(
                            e?.target?.value,
                            index,
                            configIndex,
                            config?.type,
                          )
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        ))}
      <Typography.Title level={5}>Update EPC Config</Typography.Title>
      {updateEpcConfig &&
        updateEpcConfig?.map((item, index) => (
          <Row
            align="middle"
            key={`${item?.title}`}
            className="config-form-row"
          >
            <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
              {item?.title} :
            </Col>
            <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
              <Row gutter={[20, 12]} align="middle">
                {item?.configs?.map((config, configIndex) => (
                  <Col key={`${config?.name}`} xs={12} sm={12} md={8} lg={8}>
                    <div>{config?.title}:</div>
                    <Select
                      disabled={!!isView}
                      value={config?.value}
                      onChange={(value) =>
                        handleUpdateEpcConfig(value, index, configIndex)
                      }
                      options={config?.options}
                      placeholder={config?.placeHolder}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ))}
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Typography.Title level={5} className="mb-8">
            Google Analytics Config
          </Typography.Title>
          <Row className="config-form-row" align="middle">
            <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
              <Typography.Text>Tracking Id :</Typography.Text>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Input
                value={trackingId}
                onChange={(e) => setTrackingId(e?.target?.value)}
                placeholder="Tracking Id"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Typography.Title level={5} className="mb-8">
            Empty State Config
          </Typography.Title>
          <div className="d-flex flex-wrap">
            <div className="mb-16">
              <Typography.Text>Upgrades Image Visible :</Typography.Text>
              <Switch
                checked={
                  emptyImagesState?.find(
                    (image) =>
                      image?.key === EMPTY_STATE_IMAGES_KEYS.UPGRADES_PAGE,
                  )?.visibility
                }
                onChange={(value) =>
                  handleVisibilityChange(
                    EMPTY_STATE_IMAGES_KEYS.UPGRADES_PAGE,
                    value,
                  )
                }
                className="ml-16 mr-16"
              />
            </div>
            <div>
              <Typography.Text>Recommendation Image Visible :</Typography.Text>
              <Switch
                checked={
                  emptyImagesState?.find(
                    (image) =>
                      image?.key ===
                      EMPTY_STATE_IMAGES_KEYS.RECOMMENDATIONS_PAGE,
                  )?.visibility
                }
                onChange={(value) =>
                  handleVisibilityChange(
                    EMPTY_STATE_IMAGES_KEYS.RECOMMENDATIONS_PAGE,
                    value,
                  )
                }
                className="ml-16"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default OrganizationConfigForm;
