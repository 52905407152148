import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AppContext } from '@context';
import { ROUTES } from '@common/constants';
import { formValidatorRules } from '@common/utils';
import LogoTextComponent from '@components/LogoTextComponent';

import { UPDATE_PASSWORD } from './graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { getCurrentUser } = useContext(AppContext);
  const [resetPassword, { loading: reseting }] = useMutation(UPDATE_PASSWORD);
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  const { id } = getCurrentUser() || {};

  const onFinish = async (values) => {
    try {
      const response = await resetPassword({
        variables: {
          data: { uid: id, token, password: values?.password?.trim() },
        },
      });
      if (response) {
        history?.replace(ROUTES?.LOGIN);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="login-wrap d-flex align-center justify-center">
      <Card className="full-width">
        <div className="text-center">
          <div className="text-center d-flex justify-center align-center">
            <LogoTextComponent height="80px" />
          </div>
          <h2 className="text-center">Reset Password</h2>
          <p className="text-center">Enter a new password for your account</p>
        </div>
        <Spin spinning={reseting}>
          <Form
            name="reset-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
          >
            <Form.Item
              name="password"
              rules={[
                { ...required, message: 'Please Enter Password!' },
                password,
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter password"
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              rules={[
                { ...required, message: 'Please Enter Confirm Password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value !== getFieldValue('password')) {
                      return Promise?.reject(
                        new Error('Passwords do not match'),
                      );
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter confirm password"
              />
            </Form.Item>
            <Form.Item className="full-width mb-8">
              <Button type="primary" className="full-width" htmlType="submit">
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-0">
              <Button
                type="link"
                onClick={() => {
                  history?.push(ROUTES?.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default ChangePassword;
