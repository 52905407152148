import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';

import { formValidatorRules } from '@common/utils';

import 'react-quill/dist/quill.snow.css';
import { UI_LABEL_TYPES } from '../../../common/constants';

const OrganizationUiLabelForm = ({ form, onFinish }) => {
  const { Item } = Form;
  const { required } = formValidatorRules;
  const type = Form?.useWatch('type', form);
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        className="form master-form"
        onFieldsChange={(changedFields) => {
          if (changedFields?.[0]?.name?.includes('type')) {
            form?.setFieldValue('value', '');
          }
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Key"
              name="key"
              rules={[{ ...required, message: 'Please Enter Key!' }]}
            >
              <Input disabled placeholder="Enter Key" maxLength={50} />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Type"
              name="type"
              initialValue={UI_LABEL_TYPES?.[0]?.value}
              rules={[{ ...required, message: 'Please Select Type!' }]}
            >
              <Select options={UI_LABEL_TYPES} placeholder="Select Type" />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Value" name="value">
              {type === UI_LABEL_TYPES?.[0]?.value ? (
                <Input placeholder="Enter Value" maxLength={500} />
              ) : (
                <ReactQuill theme="snow" placeholder="Enter Value" />
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default OrganizationUiLabelForm;
