import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_HOUSE_SHAPE = gql`
  mutation($data: CreateHouseShapeInput!) {
    createHouseShape(data: $data) {
      message
    }
  }
`;

export const UPDATE_HOUSE_SHAPE = gql`
  mutation($where: HouseShapeWhereUniqueInput!, $data: UpdateHouseShapeInput!) {
    updateHouseShape(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_HOUSE_SHAPE = gql`
  mutation($where: HouseShapeWhereUniqueInput!) {
    deleteHouseShape(where: $where) {
      message
    }
  }
`;
