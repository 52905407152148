import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { AppContext } from '@context';
import { getSignedUrl, fileUpload } from '@common/utils';
import CommonHeader from '@components/CommonHeader';
import { ROUTES } from '@common/constants';

import RoofTypesForm from '../components/RoofTypesForm';
import { CREATE_ROOF_TYPE, UPDATE_ROOF_TYPE } from '../graphql/Mutations';
import { GET_ROOF_TYPES, GET_SIGNED_URL } from '../graphql/Queries';
import history from '../../../historyData';

const CreateRoofTypes = () => {
  const { mode, id } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isView, setIsView] = useState(false);
  const [roofTypeLogo, setRoofTypeLogo] = useState([]);

  const [createRoofType, { loading: createLoading }] = useMutation(
    CREATE_ROOF_TYPE,
  );

  const [updateRoofType, { loading: updateLoading }] = useMutation(
    UPDATE_ROOF_TYPE,
  );

  const [getRoofType, { data: roofTypeData }] = useLazyQuery(GET_ROOF_TYPES);

  const fetchRoofType = async () => {
    try {
      await getRoofType({
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (mode === 'view') {
      setIsView(true);
    }
    if (id) {
      fetchRoofType();
    }
  }, []);

  const onEdit = () => {
    setIsView(false);
    history?.push(`${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}/edit/${id}`);
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(`${ROUTES?.MASTER}${ROUTES?.ROOF_TYPES}`);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const { isActive, tooltipTitle, tooltipBody, ...rest } = values;

    const dataToSend = {
      ...rest,
      tooltip: { title: tooltipTitle, body: tooltipBody },
      isActive: JSON.parse(isActive),
    };

    try {
      let imgKey;
      if (values?.imageUrl && !roofTypeLogo?.[0]?.url) {
        await getSignedUrl(
          values?.imageUrl?.[0]?.originFileObj,
          GET_SIGNED_URL,
        )?.then(async (res) => {
          const signedImageUrl = res?.getRoofTypeUploadSignedUrl?.signedUrl;
          imgKey = res?.getRoofTypeUploadSignedUrl?.key;
          await fileUpload(
            signedImageUrl,
            values?.imageUrl?.[0]?.originFileObj,
          );
          dataToSend.imageUrl = imgKey;
        });
      } else if (roofTypeLogo?.[0]?.url) {
        delete dataToSend.imageUrl;
      }
      if (id) {
        try {
          await updateRoofType({
            variables: {
              where: { id },
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createRoofType({
            variables: {
              data: {
                ...dataToSend,
              },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (roofTypeData?.roofType?.data) {
      const dataToPass = {
        key: roofTypeData?.roofType?.data?.key,
        label: roofTypeData?.roofType?.data?.label,
        description: roofTypeData?.roofType?.data?.description,
        imageUrl: roofTypeData?.roofType?.data?.imageUrl,
        tooltipTitle: roofTypeData?.roofType?.data?.tooltip?.title,
        tooltipBody: roofTypeData?.roofType?.data?.tooltip?.body,
        isActive: JSON.stringify(roofTypeData?.roofType?.data?.isActive),
      };
      setRoofTypeLogo([{ url: roofTypeData?.roofType?.data?.imageUrl }]);
      form?.setFieldsValue(dataToPass);
    }
  }, [roofTypeData]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <CommonHeader
        heading={id ? `${mode} Roof Type` : 'Add Roof Type'}
        customLink={ROUTES?.MASTER}
      >
        {isView ? (
          <Button className="common-button" size="middle" onClick={onEdit}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              type="primary"
              className="common-button mr-16"
              size="middle"
              onClick={onCancelEdit}
            >
              Cancel
            </Button>
            <Button
              className="common-button"
              size="middle"
              onClick={form?.submit}
              loading={createLoading || updateLoading}
              disabled={createLoading || updateLoading || isDisabled}
            >
              Save
            </Button>
          </>
        )}
      </CommonHeader>
      <Card className="full-height-with-nav" title="Roof Type">
        <RoofTypesForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          isView={isView}
          roofTypeLogo={roofTypeLogo}
          setRoofTypeLogo={setRoofTypeLogo}
        />
      </Card>
    </>
  );
};

export default CreateRoofTypes;
