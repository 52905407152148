import { useLazyQuery } from '@apollo/client';
import { Badge, Button } from 'antd';
import { map } from 'lodash';
import { React, useEffect, useState } from 'react';

import {
  BREAKPOINTS,
  DATE_FORMATS,
  EXPORTS,
  EXPORT_JOBS_STATUS,
  LIMIT,
  SORT,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import SelectComponent from '../../../components/SelectComponent';
import { EXPORT_JOBS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialExportsFilter = {
  limit: LIMIT,
  skip: 0,
};

const initialExportsSort = [
  {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  },
];

const ExportTable = () => {
  const [exportList, setExportList] = useState([]);
  const [isEmptyDeliveryList, setIsEmptyDeliveryList] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [selectedType, setSelectedType] = useState('ALL');
  const [isExportsLoading, setIsExportsLoading] = useState(true);

  const [exportJobs] = useLazyQuery(EXPORT_JOBS, {
    onCompleted: (response) => {
      setExportList([...response?.exportJobs?.data]);
      if (
        response?.exportJobs?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyDeliveryList(true);
      } else {
        setIsEmptyDeliveryList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.exportJobs?.count,
      };
      setPaginationProp(pagination);
      setIsExportsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsExportsLoading(true);
    setExportList([]);
    exportJobs({
      variables: {
        filters: {
          ...initialExportsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          type: selectedType === 'ALL' ? undefined : selectedType,
        },
        sort: sortedInfo?.column
          ? [
              {
                sortOn: sortedInfo?.field,
                sortBy: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              },
            ]
          : initialExportsSort,
      },
    });
  }, [selectedType]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsExportsLoading(true);
    setSortedInfo(sorter);
    exportJobs({
      variables: {
        filters: {
          ...initialExportsFilter,
          skip,
          limit: pagination?.pageSize,
          type: selectedType === 'ALL' ? undefined : selectedType,
        },
        sort: sorter?.column
          ? [
              {
                sortOn: sorter?.field,
                sortBy: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              },
            ]
          : initialExportsSort,
      },
    });
  };

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => EXPORTS?.[record?.type]?.label,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      sorter: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME) || '-',
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={EXPORT_JOBS_STATUS?.[record?.status]?.color}
          text={EXPORT_JOBS_STATUS?.[record?.status]?.text}
        />
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              className="table-action-primary-btn"
              // eslint-disable-next-line no-undef
              onClick={() => window.open(record?.fileUrl, '_blank')}
              disabled={record?.status !== EXPORT_JOBS_STATUS?.COMPLETED?.value}
            >
              Download
            </Button>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyDeliveryList ? '' : <span />,
  };

  return (
    <>
      <div className="d-flex justify-end mb-16 align-center">
        <SelectComponent
          allowClear={false}
          showSearch={false}
          placeholder="Select Export Type"
          className="export-type-select"
          options={map(EXPORTS, (item) => item)}
          value={selectedType}
          onChange={(value) => setSelectedType(value)}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={exportList || []}
        loading={isExportsLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default ExportTable;
