import { Input } from 'antd';
import React from 'react';

import { NUMBER_FIELD_TYPES } from '@common/constants';
import './styles/NumberComponent.less';

const NumberComponent = (props) => {
  const {
    name = '',
    className = '',
    min = 0,
    isFractional = true,
    type,
    ...rest
  } = props;

  const disallowedKeys = ['e', 'E', '+', '-'];
  if (!isFractional) {
    disallowedKeys?.push('.');
  }

  const handleKeyDown = (evt) => {
    if (disallowedKeys?.includes(evt?.key)) {
      evt?.preventDefault();
    }
  };

  const handleOnInput = (evt) => {
    const { value } = evt?.target;
    let maxLength;

    switch (type) {
      case NUMBER_FIELD_TYPES.PRICE:
        maxLength = value?.includes('.') ? 11 : 8;
        break;
      case NUMBER_FIELD_TYPES.BORDER_RADIUS:
        maxLength = 3;
        break;
      case NUMBER_FIELD_TYPES.HEIGHT_WIDTH:
        maxLength = 3;
        break;
      default:
        maxLength = 20;
        break;
    }

    if (maxLength && value?.length > maxLength) {
      // eslint-disable-next-line no-param-reassign
      evt.target.value = value?.slice(0, maxLength);
    }
  };

  return (
    <Input
      className={`number-component ${className}`}
      type="number"
      allowClear
      placeholder={`Enter ${name || ''}`}
      min={min}
      onKeyDown={handleKeyDown}
      onInput={handleOnInput}
      {...rest}
    />
  );
};

export default NumberComponent;
