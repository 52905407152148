import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { LIMIT, SORT } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { LIST_COMPANIES } from '../graphql/Queries';

const { Option } = Select;

const CompanySelect = (props) => {
  const { companyDetail, ...rest } = props;
  const [companyList, setCompanyList] = useState([]);
  const [companySearchTerm, setCompanySearchTerm] = useState('');
  const [isAllCompanyFetched, setIsAllCompanyFetched] = useState(false);
  const [isCompanyAllowClear, setIsCompanyAllowClear] = useState(false);
  const [isCompanyDropdownVisible, setIsCompanyDropdownVisible] = useState(
    false,
  );
  const [isFetchMoreCompanyLoading, setIsFetchMoreCompanyLoading] = useState(
    false,
  );

  const [listCompanies, { loading: isCompanyLoading }] = useLazyQuery(
    LIST_COMPANIES,
    {
      onCompleted: (response) => {
        if (response?.listCompanies?.data?.length < LIMIT) {
          setIsAllCompanyFetched(true);
        }
        setIsFetchMoreCompanyLoading(false);
        if (companyDetail) {
          setCompanyList(
            uniqBy(
              [
                companyDetail?.brand,
                ...companyList,
                ...response?.listCompanies?.data,
              ],
              'id',
            ),
          );
          return;
        }
        setCompanyList([...companyList, ...response?.listCompanies?.data]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setCompanySearchTerm('');
    setCompanyList([]);
    listCompanies({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: companySearchTerm,
        },
        sort: {
          sortOn: 'name',
          sortBy: SORT?.DESC,
        },
      },
    });
    setIsAllCompanyFetched(false);
  }, [isCompanyDropdownVisible]);

  const handleCategoriesScroll = (event) => {
    if (!isAllCompanyFetched && !isCompanyLoading) {
      const target = event?.target;
      const currentLength = companyList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreCompanyLoading(true);
        listCompanies({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: companySearchTerm,
            },
            sort: {
              sortOn: 'name',
              sortBy: SORT?.DESC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllCompanyFetched(false);
    setCompanyList([]);
    setCompanySearchTerm(trimSearch);
    listCompanies({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          sortOn: 'name',
          sortBy: SORT?.DESC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isCompanyLoading) {
      setIsCompanyAllowClear(false);
    } else {
      setIsCompanyAllowClear(true);
    }
  }, [isCompanyLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isCompanyLoading && isCompanyDropdownVisible) ||
        isFetchMoreCompanyLoading
      }
      placeholder="Select Organisation"
      optionFilterProp="children"
      allowClear={isCompanyAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsCompanyDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMoreCompanyLoading && isCompanyLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {companyList?.map((company) => (
        <Option key={company?.id} value={company?.id}>
          {company?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default CompanySelect;
