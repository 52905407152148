import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_HEATING_SYSTEM = gql`
  mutation($data: CreateHeatingSystemInput!) {
    createHeatingSystem(data: $data) {
      message
    }
  }
`;

export const UPDATE_HEATING_SYSTEM = gql`
  mutation(
    $where: HeatingSystemWhereUniqueInput!
    $data: UpdateHeatingSystemInput!
  ) {
    updateHeatingSystem(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_HEATING_SYSTEM = gql`
  mutation($where: HeatingSystemWhereUniqueInput!) {
    deleteHeatingSystem(where: $where) {
      message
    }
  }
`;
