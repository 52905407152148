import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Space } from 'antd';
import { capitalize, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AppContext } from '@context';
import { MODULES } from '@common/constants';
import { fileUpload, openNotification } from '@common/utils';
import LoaderComponent from '@components/LoaderComponent';
import Portal from '@components/Portal';

import { UPDATE_USER } from '../../auth/graphql/Mutations';
import { GET_USER } from '../../auth/graphql/Queries';
import ChangePasswordModal from '../components/ChangePasswordModal';
import ProfileForm from '../components/ProfileForm';
import { GET_PROFILE_IMAGE_UPLOAD_SIGNED_URL } from '../graphql/Queries';

function Profile() {
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const { firstName = '', lastName = '', id } = getCurrentUser() || {};

  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form?.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState([]);
  const [userData, setUserData] = useState({});
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (response) => {
      setUserData(response?.getUser);
      const imageUrl = response?.getUser?.profileImage;
      if (imageUrl) {
        setUserProfilePic([{ url: imageUrl }]);
      }
      setIsUserDataLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getProfileImageUploadSignedUrl] = useLazyQuery(
    GET_PROFILE_IMAGE_UPLOAD_SIGNED_URL,
    {
      fetchPolicy: 'network-only',
      onError() {
        setBtnLoading(false);
      },
    },
  );

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: () => {
      form?.setFieldsValue(userData);
      setBtnLoading(false);
    },
  });

  useEffect(() => {
    setIsUserDataLoading(true);
    getUser();
  }, []);

  const handleDiscard = () => {
    setIsUserDataLoading(true);
    getUser();
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    setIsDisabled(true);
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    const uuid = uuidv4();
    const photoKeys = {
      profileImage: '',
    };
    let images = {};
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const userObj = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
    };

    if (!values?.profileImage?.url && userProfilePic?.length > 0) {
      const documentArray = [
        {
          name: 'profile-photo',
          value: values?.profileImage,
        },
      ];
      await Promise.all(
        map(documentArray, async (item) => {
          if (item?.value && typeof item?.value === 'object') {
            const { name } = item?.value?.[0];
            const ext = name?.substring(name?.lastIndexOf('.') + 1);
            const timestamp = Date?.now();
            const filename = name?.split('.')?.slice(0, -1)?.join('.');
            const newFilename = `${timestamp}_${filename}.${ext}`;
            const fileKey = `profile/${uuid}/${newFilename}`;

            const res = await getProfileImageUploadSignedUrl({
              variables: {
                data: {
                  fileName: fileKey,
                },
              },
            });

            if (res) {
              const {
                signedUrl,
                key,
              } = res?.data?.getProfileImageUploadSignedUrl;

              try {
                await fileUpload(
                  signedUrl,
                  item?.value?.[0]?.originFileObj,
                )?.catch((error) => {
                  if (error === 403) {
                    openNotification(
                      'error',
                      `${item?.name
                        ?.toString()
                        ?.toUpperCase()} upload failed. Please try again.`,
                    );
                  }
                });
                switch (item?.name) {
                  case 'profile-photo':
                    photoKeys.profileImage = key;
                    break;
                  default:
                    break;
                }
              } catch (error) {
                setBtnLoading(false);
                return error;
              }
            }
          }
        }),
      ).then(() => {
        images = {
          ...photoKeys,
        };
      });
    }
    try {
      const res = await updateUser({
        variables: {
          where: { id },
          data: {
            ...userObj,
            profileImage:
              typeof values?.profileImage !== 'string'
                ? images?.profileImage || ''
                : undefined,
          },
        },
      });

      if (res?.data) {
        setIsUserDataLoading(true);
        getUser();
      }
    } catch (error) {
      setBtnLoading(false);
      return error;
    }
    setBtnLoading(false);
    setIsDisabled(true);
  };

  const initialValues = {
    ...userData,
    state: userData?.state,
    city: userData?.city,
    userPincode: userData?.pincode?.pincode,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.PROFILE}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div key="actionbutton" className="text-right">
          <Space>
            <Button
              onClick={handleDiscard}
              disabled={btnLoading || isDisabled}
              type="primary"
              className="common-button mr-16"
            >
              Discard Changes
            </Button>
            <Button
              onClick={form.submit}
              loading={btnLoading}
              disabled={isDisabled}
              htmlType="submit"
              className="common-button"
            >
              Save
            </Button>
          </Space>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div key="actionbutton" className="text-right">
          <Space>
            <Button
              onClick={handleDiscard}
              disabled={btnLoading || isDisabled}
              type="primary"
              className="common-button mr-16"
            >
              Discard Changes
            </Button>
            <Button
              onClick={form.submit}
              loading={btnLoading}
              disabled={isDisabled}
              htmlType="submit"
              className="common-button"
            >
              Save
            </Button>
          </Space>
        </div>
      </Portal>
      <ChangePasswordModal
        visible={isPasswordVisible}
        setVisible={setIsPasswordVisible}
      />
      <Card
        className="full-height-with-nav"
        title={`${capitalize(firstName)} ${capitalize(lastName)} Details`}
        extra={
          <Button
            type="primary"
            size="small"
            onClick={() => setIsPasswordVisible(true)}
            className="change-password-btn"
          >
            Change Password
          </Button>
        }
      >
        {isUserDataLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isUserDataLoading}
          />
        ) : (
          <ProfileForm
            form={form}
            setIsDisabled={setIsDisabled}
            onFinish={onFinish}
            initialValues={initialValues}
            userProfilePic={userProfilePic}
            userData={userData}
            setUserProfilePic={setUserProfilePic}
          />
        )}
      </Card>
    </>
  );
}
export default Profile;
