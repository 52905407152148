import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES, TABS } from '@common/constants';

import './organizations.less';
import Organizations from './pages/Organization';
import OrganizationConfiguration from './pages/OrganizationConfigurations';
import OrganizationDetails from './pages/OrganizationDetails';
import OrganizationEmailConfig from './pages/OrganizationEmailConfig';
import OrganizationFieldConfig from './pages/OrganizationFieldConfig';
import OrganizationUiLabels from './pages/OrganizationUiLabels';

const OrganizationWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.ORGANIZATIONS} component={Organizations} />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/add/${TABS?.ORGANIZATION?.key}`}
      render={(props) => <OrganizationDetails {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:mode/:id/${TABS?.ORGANIZATION?.key}`}
      render={(props) => <OrganizationDetails {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:mode/:id/${TABS?.ORGANIZATION_CONFIGURATION?.key}`}
      render={(props) => <OrganizationConfiguration {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:mode/:id/${TABS?.ORGANIZATION_UI_LABELS?.key}`}
      render={(props) => <OrganizationUiLabels {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:mode/:id/${TABS?.ORGANIZATION_EMAIL_CONFIG?.key}`}
      render={(props) => <OrganizationEmailConfig {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORGANIZATIONS}/:mode/:id/${TABS?.FIELD_CONFIG?.key}`}
      render={(props) => <OrganizationFieldConfig {...props} />}
    />
  </Switch>
);

export default OrganizationWrapper;
