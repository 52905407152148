import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Space, Typography, Upload } from 'antd';
import React, { useState } from 'react';

import { formValidatorRules, getBase64, validateImage } from '@common/utils';
import CommonPreview from '@components/CommonPreview';

const { Item } = Form;
const { required, email, name } = formValidatorRules;

const OrganizationForm = ({
  form,
  onFinish,
  handleShowPrompt,
  isView,
  organizationId,
  organizationLogo = [],
  setOrganizationLogo,
}) => {
  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const handleChange = (info) => {
    validateImage(info, form, setOrganizationLogo, 'organizationLogo', true);
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setPreviewImage(fileObj?.url || preview);
  };
  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={previewImage}
        setImage={setPreviewImage}
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        className={`${isView ? 'common-view-form' : 'form'} organization-form`}
      >
        <Space direction="vertical" size="middle">
          <Typography.Title level={5}>Basic Details</Typography.Title>
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Item name="logoUrl" label="Organisation Logo">
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  maxCount={1}
                  fileList={organizationLogo}
                  beforeUpload={() => false}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  className="avatar-uploader"
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                  }}
                >
                  {organizationLogo?.length === 0 && <PlusOutlined />}
                </Upload>
              </Item>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Item
                    label="Name"
                    name="name"
                    rules={[
                      { ...required, message: 'Please Enter Name!' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter Name" maxLength={50} />
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Item
                    label="Email ID"
                    name="email"
                    rules={[
                      { ...required, message: 'Please Enter Email!' },
                      email,
                    ]}
                  >
                    <Input
                      disabled={!!organizationId}
                      placeholder="Enter Email"
                      maxLength={255}
                    />
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Item
                    label="URL"
                    name="url"
                    rules={[{ ...required, message: 'Please Enter Url!' }]}
                  >
                    <Input placeholder="Enter URL" maxLength={255} />
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Item
                    label="Slug"
                    name="slug"
                    rules={[{ ...required, message: 'Please Enter Slug!' }]}
                  >
                    <Input
                      disabled={!!organizationId}
                      placeholder="Enter Slug"
                      maxLength={255}
                    />
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

export default OrganizationForm;
