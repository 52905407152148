import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ORGANIZATION = gql`
  mutation($data: CreateCompanyInput!) {
    createCompany(data: $data) {
      message
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation($where: CompanyWhereUniqueInput!, $data: UpdateCompanyInput!) {
    updateCompany(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation($where: CompanyWhereUniqueInput!) {
    deleteCompany(where: $where) {
      message
    }
  }
`;

export const UPDATE_ORGANIZATION_STATUS = gql`
  mutation($data: UpdateCompanyInput!, $where: CompanyWhereUniqueInput!) {
    updateCompany(data: $data, where: $where) {
      message
    }
  }
`;

export const CREATE_ORGANIZATION_CONFIG = gql`
  mutation($data: CreateCompanyConfigInput!) {
    createCompanyConfig(data: $data) {
      message
    }
  }
`;

export const UPDATE_ORGANIZATION_CONFIG = gql`
  mutation(
    $data: UpdateCompanyConfigInput!
    $where: CompanyConfigWhereUniqueInput!
  ) {
    updateCompanyConfig(data: $data, where: $where) {
      message
    }
  }
`;

export const UPDATE_UI_LABEL = gql`
  mutation($data: UpdateUILabelInput!, $where: UILabelWhereUniqueInput!) {
    updateUILabel(data: $data, where: $where) {
      message
    }
  }
`;

export const REFETCH_UI_LABELS = gql`
  mutation($data: CreateCloudFrontCacheInvalidationInput!) {
    createCloudFrontCacheInvalidation(data: $data) {
      message
    }
  }
`;

export const CREATE_EMAIL_CONFIG = gql`
  mutation($data: CreateCompanyConfigInput!) {
    createCompanyConfig(data: $data) {
      message
    }
  }
`;

export const UPDATE_EMAIL_CONFIG = gql`
  mutation(
    $data: UpdateCompanyConfigInput!
    $where: CompanyConfigWhereUniqueInput!
  ) {
    updateCompanyConfig(data: $data, where: $where) {
      message
    }
  }
`;
