import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from '../../common/constants';
import './dashboard.less';
import Dashboard from './pages/Dashboard';
import Visualization from './pages/Visualization';

const DashboardWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.MAIN}
      render={(props) => <Dashboard {...props} />}
    />
    <Route
      exact
      path={ROUTES?.VISUALISATION}
      render={(props) => <Visualization {...props} />}
    />
  </Switch>
);

export default DashboardWrapper;
