import { Col, Form, Input, Row, Typography } from 'antd';
import React, { useEffect } from 'react';

import { formValidatorRules } from '@common/utils';

import { RECOMMENDATION_TEMPLATE_KEY } from '../../../common/constants';

const { required, email } = formValidatorRules;

const OrganizationEmailConfigForm = ({
  form,
  onFinish,
  handleShowPrompt,
  isView,
  organizationConfigData,
}) => {
  const { Item } = Form;

  const initialValues = {
    apiKey: '',
    baseUrl: '',
    email: '',
    name: '',
    replyToEmail: '',
    templates: [
      {
        key:
          process.env.REACT_APP_RECOMMENDATION_TEMPLATE_KEY ||
          RECOMMENDATION_TEMPLATE_KEY,
        id: '',
      },
    ],
  };

  useEffect(() => {
    if (organizationConfigData) {
      const dataToPass = {
        apiKey: organizationConfigData?.emailConfig?.apiKey,
        email: organizationConfigData?.emailConfig?.email,
        name: organizationConfigData?.emailConfig?.name,
        baseUrl: organizationConfigData?.emailConfig?.baseUrl,
        replyToEmail: organizationConfigData?.emailConfig?.replyToEmail,
        templates:
          organizationConfigData?.emailConfig?.templates?.length > 0
            ? organizationConfigData?.emailConfig?.templates?.map((item) => ({
                key: item?.key,
                id: item?.id,
              }))
            : [
                {
                  key:
                    process.env.REACT_APP_RECOMMENDATION_TEMPLATE_KEY ||
                    RECOMMENDATION_TEMPLATE_KEY,
                  id: '',
                },
              ],
      };
      form?.setFieldsValue(dataToPass);
    }
  }, [organizationConfigData]);
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        className={`${isView ? 'common-view-form' : 'form'} organization-form`}
        initialValues={initialValues}
      >
        <Typography.Title level={5}>Sendgrid Config</Typography.Title>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Api key"
              name="apiKey"
              rules={[{ ...required, message: 'Please Enter Api key!' }]}
            >
              <Input
                placeholder="Enter Api key"
                disabled={
                  !!organizationConfigData?.emailConfig?.apiKey || !!isView
                }
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Email"
              name="email"
              rules={[{ ...required, message: 'Please Enter Email!' }, email]}
            >
              <Input placeholder="Enter Email" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Name"
              name="name"
              rules={[{ ...required, message: 'Please Enter Name!' }]}
            >
              <Input placeholder="Enter Name" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Base Url"
              name="baseUrl"
              rules={[{ ...required, message: 'Please Enter Base url!' }]}
            >
              <Input placeholder="Base Url" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Reply To Email" name="replyToEmail">
              <Input placeholder="Enter Reply To Email" />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item label="Templates">
              <Form.List name="templates">
                {(field) =>
                  field.map((subField) => (
                    <div
                      key={subField.key}
                      style={{
                        display: 'flex',
                        gap: 16,
                      }}
                    >
                      <Item noStyle name={[subField.name, 'key']}>
                        <Input placeholder="Enter Key" disabled />
                      </Item>
                      <Item noStyle name={[subField.name, 'id']}>
                        <Input placeholder="Enter Template Id" />
                      </Item>
                    </div>
                  ))
                }
              </Form.List>
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default OrganizationEmailConfigForm;
