import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'antd';
import { debounce } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';

import { PlusOutlined } from '@ant-design/icons';

import { MODULES, ROUTES, SORT, TABS } from '@common/constants';
import CommonHeader from '@components/CommonHeader';

import history from '../../../historyData';
import OrganizationTable from '../components/OrganizationTable';

import { LIST_ORGANIZATIONS } from '../graphql/Queries';
import {
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION_STATUS,
} from '../graphql/Mutations';

const Organizations = () => {
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 1,
    limit: 10,
  });
  const [sortConfig, setSortConfig] = useState({
    sortBy: SORT?.DESC,
    sortOn: 'createdAt',
  });
  const [search, setSearch] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const [
    listCompanies,
    { loading: organizationLoading, data: organizationData },
  ] = useLazyQuery(LIST_ORGANIZATIONS);

  const [deleteOrganization, { loading: deleteLoading }] = useMutation(
    DELETE_ORGANIZATION,
  );

  const [updateStatus, { loading: statusLoading }] = useMutation(
    UPDATE_ORGANIZATION_STATUS,
  );

  const getCompanyList = async () => {
    const { skip, limit } = paginationConfig;
    try {
      await listCompanies({
        variables: {
          filter: { skip: (skip - 1) * limit, limit, search },
          sort: sortConfig?.sortOn ? { ...sortConfig } : {},
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getCompanyList();
  }, [paginationConfig, sortConfig, search]);

  const handleTableChange = (pagination, _filters, sorter) => {
    setPaginationConfig({
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSortConfig({
      sortOn: sorter?.order ? sorter?.field : '',
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const onSearchChange = debounce((e) => {
    const { value } = e?.target;
    setPaginationConfig({ ...paginationConfig, skip: 1 });
    setSearch(value);
  }, 600);

  const handleDeleteOrganization = async (id) => {
    try {
      const res = await deleteOrganization({ variables: { where: { id } } });
      if (res?.data) {
        setCurrentOrganization(null);
        setIsDeleteOpen(false);
        getCompanyList();
      }
    } catch (error) {
      return error;
    }
  };

  const handleDeleteModalOpen = (value) => {
    setIsDeleteOpen(true);
    setCurrentOrganization(value);
  };

  const handleOrganizationStatus = async (value) => {
    try {
      const res = await updateStatus({
        variables: {
          data: { isActive: !value?.isActive },
          where: { id: value?.id },
        },
      });
      if (res?.data) {
        setCurrentOrganization(null);
        setIsStatusOpen(false);
        getCompanyList();
      }
    } catch (error) {
      return error;
    }
  };

  const handleStatusModalOpen = (value) => {
    setIsStatusOpen(true);
    setCurrentOrganization(value);
  };

  return (
    <>
      <CommonHeader
        heading={MODULES?.ORGANIZATIONS}
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <Button
          className="common-button"
          size="middle"
          icon={<PlusOutlined />}
          onClick={() =>
            history?.push(
              `${ROUTES?.ORGANIZATIONS}/add/${TABS?.ORGANIZATION?.key}`,
            )
          }
        >
          Add New Organisation
        </Button>
      </CommonHeader>
      <Card className="full-height-without-nav">
        <OrganizationTable
          handleTableChange={handleTableChange}
          paginationConfig={paginationConfig}
          sortConfig={sortConfig}
          organizationData={organizationData?.listCompanies}
          organizationLoading={organizationLoading}
          onSearchChange={onSearchChange}
          handleDeleteModalOpen={handleDeleteModalOpen}
          handleStatusModalOpen={handleStatusModalOpen}
        />
      </Card>
      <Modal
        title="Delete the Organisation"
        okText="Yes"
        cancelText="No"
        open={isDeleteOpen}
        onOk={() => handleDeleteOrganization(currentOrganization?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsDeleteOpen(false);
          setCurrentOrganization(null);
        }}
      >
        Are you sure you want to delete this organisation?
      </Modal>
      <Modal
        title="Organisation Status"
        okText="Yes"
        cancelText="No"
        open={isStatusOpen}
        onOk={() => handleOrganizationStatus(currentOrganization)}
        okButtonProps={{ loading: statusLoading }}
        onCancel={() => {
          setIsStatusOpen(false);
          setCurrentOrganization(null);
        }}
      >
        Are you sure you want to change status of this organisation?
      </Modal>
    </>
  );
};
export default Organizations;
