import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { MATERIAL_TABS, ROUTES } from '@common/constants';

const MaterialTab = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { id, mode /* materialId */ } = useParams();

  const [activeTabKey, setActiveTabKey] = useState('details');

  const onTabChange = (key) => {
    setActiveTabKey(key);
    if (key === 'materials') {
      history?.push(
        `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/${mode}${ROUTES?.MATERIALS}/${id}`,
      );
    }
    if (key === 'material-categories') {
      history?.push(
        `${ROUTES?.MASTER}${ROUTES?.MATERIAL_CATEGORIES}/${mode}/${id}`,
      );
    }
    // if (location?.pathname?.includes('add')) {
    //   history?.push(`${ROUTES?.ORGANIZATIONS}/add/${key}`);
    // } else {
    //   history?.push(`${ROUTES?.ORGANIZATIONS}/${mode}/${id}/${key}`);
    // }
  };

  useEffect(() => {
    if (location?.pathname?.includes(MATERIAL_TABS?.MATERIAL_CATEGORIES?.key)) {
      setActiveTabKey(MATERIAL_TABS?.MATERIAL_CATEGORIES?.key);
    }
    if (
      location?.pathname?.includes(
        `${MATERIAL_TABS?.MATERIAL_CATEGORIES?.key}/${mode}/${MATERIAL_TABS?.MATERIALS?.key}`,
      )
    ) {
      setActiveTabKey(MATERIAL_TABS?.MATERIALS?.key);
    }
  }, [location]);

  const tabList = [
    {
      key: MATERIAL_TABS?.MATERIAL_CATEGORIES?.key,
      label: MATERIAL_TABS?.MATERIAL_CATEGORIES?.label,
      children,
    },
    {
      key: MATERIAL_TABS?.MATERIALS?.key,
      label: MATERIAL_TABS?.MATERIALS?.label,
      children,
    },
  ];

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={tabList}
      />
    </div>
  );
};

export default MaterialTab;
