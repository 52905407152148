import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIST_HOUSE_TYPES = gql`
  query($filter: AdminListHouseTypeFilter, $sort: [ListHouseTypeSort!]) {
    adminHouseTypes(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        description
        id
        tooltip {
          title
          body
        }
        key
        label
        isActive
      }
    }
  }
`;

export const GET_HOUSE_TYPES = gql`
  query($where: HouseTypeWhereUniqueInput!) {
    houseType(where: $where) {
      data {
        description
        tooltip {
          title
          body
        }
        key
        label
        isActive
      }
    }
  }
`;
