import { useLazyQuery } from '@apollo/client';
import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { CHART_X_AXIS, DATE_FORMATS } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { WITHOUT_EPC_DROP_OFF_RATES } from '../graphql/Queries';
import CommonEpcChart from './CommonEpcChart';
import CompanySelect from './CompanySelect';

const { RangePicker } = DatePicker;

const WithoutEpcChart = () => {
  const [withoutEpcDropOff, setWithoutEpcDropOff] = useState([]);
  const [isWithoutEpcDropOffLoading, setIsWithoutEpcDropOffLoading] = useState(
    {},
  );
  const [selectedCompanyWithoutEpc, setSelectedCompanyWithoutEpc] = useState(
    '',
  );
  const [
    selectedDateRangeWithoutEpc,
    setSelectedDateRangeWithoutEpc,
  ] = useState({});

  const [withoutEpcDropOffRates] = useLazyQuery(WITHOUT_EPC_DROP_OFF_RATES, {
    onCompleted: (response) => {
      setWithoutEpcDropOff(response?.withoutEpcDropOffRates);
      setIsWithoutEpcDropOffLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsWithoutEpcDropOffLoading(true);
    withoutEpcDropOffRates();
  }, []);

  useEffect(() => {
    setIsWithoutEpcDropOffLoading(true);
    const filters = {
      dateRange:
        selectedDateRangeWithoutEpc?.from && selectedDateRangeWithoutEpc?.to
          ? {
              from: selectedDateRangeWithoutEpc?.from,
              to: selectedDateRangeWithoutEpc?.to,
            }
          : undefined,
      organizationId: selectedCompanyWithoutEpc || undefined,
    };
    withoutEpcDropOffRates({
      variables: {
        filters,
      },
    });
  }, [selectedCompanyWithoutEpc, selectedDateRangeWithoutEpc]);

  const withoutEpcDropOffWithLabel = withoutEpcDropOff?.map((data) => ({
    count: data?.count,
    type: CHART_X_AXIS?.[data?.type]?.label,
  }));

  const onRangePickerChangeWithoutEpc = (values) => {
    const rangeObj = {
      from: values?.[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };
    setSelectedDateRangeWithoutEpc(values ? rangeObj : {});
  };

  return (
    <>
      <div className="d-flex align-center justify-between mb-40 chart-filter">
        <h3>Without EPC Drop Off Rates</h3>
        <div className="ml-16">
          <CompanySelect
            className="filter-select mr-16"
            onChange={(value) => setSelectedCompanyWithoutEpc(value)}
          />
          <RangePicker
            popupClassName="picker-with-scroll"
            format={DATE_FORMATS?.REGULAR}
            onChange={onRangePickerChangeWithoutEpc}
            disabledDate={(current) =>
              current && current?.startOf('day') > moment()?.startOf('day')
            }
            placeholder={['From Date', 'To Date']}
          />
        </div>
      </div>
      {isWithoutEpcDropOffLoading ? (
        <LoaderComponent setHeight={50} />
      ) : (
        <CommonEpcChart data={withoutEpcDropOffWithLabel} />
      )}
    </>
  );
};

export default WithoutEpcChart;
