import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DASHBOARD_COUNTS = gql`
  query DashboardCounts($filters: DashboardCountsFilter) {
    dashboardCounts(filters: $filters) {
      totalCarbonHomeResult
      totalCarbonLikedMeasuresHomeResult
      totalCarbonSaved
      totalMeasuresGenerated
      totalRecommendationsGenerated
      totalUsers
      totalUpgradeSelected
      totalUsersWithRecommendation
      totalUsersWithoutRecommendation
      totalCostCurrentResult
      totalCostFutureResult
      totalCostSaving
      totalSubscribedUsers
      totalExistingUser
      totalNewUsers
    }
  }
`;

export const DASHBOARD_MOST_LIKED_RECOMMENDATIONS = gql`
  query DashboardMostLikedRecommendations($filters: MostLikedRecommendations) {
    dashboardMostLikedRecommendations(filters: $filters) {
      count
      data {
        likes
        recommendation
      }
    }
  }
`;

export const LIST_COMPANIES = gql`
  query ListCompanies(
    $filter: ListCompaniesFilter
    $sort: ListCompaniesSort
    $where: ListCompaniesWhereInput
  ) {
    listCompanies(filter: $filter, sort: $sort, where: $where) {
      count
      data {
        name
        id
      }
    }
  }
`;

export const WITHOUT_EPC_DROP_OFF_RATES = gql`
  query withoutEpcDropOffRates($filters: DashboardCommonFilter) {
    withoutEpcDropOffRates(filters: $filters) {
      count
      type
    }
  }
`;

export const WITH_EPC_DROP_OFF_RATES = gql`
  query epcDropOffRates($filters: DashboardCommonFilter) {
    epcDropOffRates(filters: $filters) {
      count
      type
    }
  }
`;

export const HOUSE_DESCRIPTION_DATA = gql`
  query houseDescriptionData($filters: DashboardCommonFilter) {
    houseDescriptionData(filters: $filters) {
      description
      data {
        totalCount
        withEpcCount
        withoutEpcCount
        recommendations {
          totalCount
          withEpcCount
          withoutEpcCount
        }
      }
    }
  }
`;
